/* Title & Main Pic */
.scroll {
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
}
.eq_container {
  width: 96%;
  height: auto;
  position: relative;
  padding: 6px 2% 0px 2%;
  z-index: 110;
  max-width: 1200px;
  margin: 40px auto 0px auto;
  border-radius: 12px;
}

.eq_top_sect {
  width: 100%;
  float: left;
  position: relative;
  padding: 16px 0px 32px 16px;
  background: rgb(22, 60, 87);
  background: linear-gradient(109deg, rgb(13 45 67) 0%, rgb(13 45 67 / 0%) 59%);
  border-radius: 12px;
}

.eq_categories_sect {
  width: 100%;
  min-width: 1080px;
  float: left;
  position: relative;
  padding: 32px 0px 16px 40px;
  background: rgb(22, 60, 87);
  background: linear-gradient(
    109deg,
    rgb(128 128 128 / 8%) 0%,
    rgb(13 45 67 / 0%) 59%
  );
  border-radius: 12px;
}

.eq_top_bg {
  width: 50vw;
  height: 50vh;
  position: absolute;
  border-radius: 12px;
  background-image: url(../img/top_bg.png);
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 49%;
  opacity: 0.2;
  left: 0px;
  top: 0px;
  z-index: 100;
}

.eq_top_bg.short {
  max-height: 157px;
}

.eq_content {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  position: relative;
  padding: 24px 24px 34px 24px;
  background: linear-gradient(
    114deg,
    rgb(12 43 65 / 41%) 0%,
    rgb(41 94 130 / 34%) 44%,
    rgb(39 168 255 / 0%) 100%
  );
  border-radius: 8px;
  z-index: 150;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  -webkit-box-shadow: rgb(0 0 0 / 8%) 0px 0px 5px 0px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 5px 0px;
}

.eq_top_img {
  width: 100%;
  height: auto;
  position: absolute;
  right: -66%;
  top: -8%;
  transform: translate(0%, 0%);
  z-index: 1;
}

.eq_sentence {
  width: 100%;
  height: auto;
  display: inline-flex;
  color: #ffffff;
}

.eq_sentence_l {
  width: 30%;
  height: auto;
  padding: 0% 0%;
  position: relative;
  color: rgba(255, 255, 255, 1);
  font-weight: 200;
  letter-spacing: 0.2px;
  float: left;
}

.eq_sentence_r {
  width: max-content;
  height: auto;
  padding: 0% 0%;
  position: relative;
  color: rgb(229 229 229);
  font-weight: 200;
  font-size: 14px;
  letter-spacing: 0.2px;
  float: right;
  text-align: right;
}

.eq_b8 {
  margin-bottom: 8px;
}

.eq_b32 {
  margin-bottom: 32px;
}

.eq_back {
  width: 100%;
  margin: 12px auto 0px auto;
  padding-bottom: 32px;
}

.eq_back_link {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.3px;
  transition: 0.3s all;
  color: rgba(77, 143, 199, 1) !important;
  filter: brightness(1);
  cursor: pointer;
}

.eq_back_link:hover {
  transition: 0.2s all;
  color: rgba(77, 143, 199, 1);
  filter: brightness(6);
}

.eq_sentence_title {
  width: max-content;
  min-width: 313px;
  height: auto;
  text-align: left;
  padding-right: 48px;
}

.eq_sentence_cont {
  width: 40vw;
  height: auto;
  text-align: left;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.3px;
  line-height: 24px;
  position: relative;
  left: 0px;
  top: 50%;
  transform: translate(0px, 12%);
  max-width: 520px;
  border-left: 1px solid #ffffff17;
  padding-left: 20px;
}

.eq_image_r {
  width: 100%;
  height: auto;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(46%, -14%);
}

.eq_sum_issue {
  width: 100%;
  margin: 40px auto 0px auto;
  max-width: 1200px;
  position: relative;
  left: 24px;
  padding: 24px;
  background: linear-gradient(
    109deg,
    rgb(73 104 128 / 24%) 0%,
    rgb(23 56 79 / 0%) 59%
  );
  border-radius: 8px;
  z-index: 150;
  -webkit-box-shadow: rgb(0 0 0 / 8%) 0px 0px 5px 0px;
  box-shadow: rgb(0 0 0 / 2%) -6px -6px 9px 0px;
  z-index: 500;
}

.eq_label {
  cursor: default;
  top: -40px;
  position: relative;
  width: fit-content;
  padding: 2px 10px;
  background-color: rgb(20 51 77);
  border-radius: 6px;
  margin-left: -4px;
  color: rgba(208, 208, 208, 1);
  font-weight: 400;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.eq_summary_sec {
  width: 100%;
  height: auto;
  float: left;
  margin-top: -16px;
}

.eq_sum_bar {
  width: 120px;
  float: left;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px dashed rgb(124 124 124);
  padding: 0px;
  height: auto;
  position: relative;
  transition: 0.3s ease;
  min-height: 190px;
  max-height: 190px;
  margin: 10px;
}

.eq_sum_chart {
  width: 100%;
  height: 100%;
  bottom: 0px;
  padding: 2px 3px;
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  line-height: 16px;
  text-shadow: 0px 1px 1px rgb(255 255 255);
}

.eq_sum_data {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  z-index: 400;
  max-width: min-content;
  margin-left: 32px;
}

.eq_sum_data_title {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 13px;
}

.eq_sum_data_cont {
  width: 100%;
  height: auto;
  float: left;
  display: inline-flex;
}

.eq_sum_data_box {
  width: 16%;
  height: auto;
  padding: 12px 8px 12px 12px;
  background: linear-gradient(
    129deg,
    rgb(2 12 18 / 41%) 0%,
    rgb(20 44 61 / 49%) 44%,
    rgb(39 168 255 / 0%) 100%
  );
  border-radius: 10px;
  margin-right: 12px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(195 195 195 / 3%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(8px);
  position: relative;
  z-index: 500;
}

.eq_sum_ico {
  width: max-content;
  float: right;
  margin-bottom: 12px;
}

.eq_sum_cont {
  width: 100%;
  float: left;
  text-align: left;
  letter-spacing: 0.3px;
}

.eq_sum_space {
  max-width: 150px;
  min-width: 150px;
  min-height: 114px;
}

.eq_sum_space2 {
  max-width: 180px;
  min-width: 180px;
}

.graph_equip_date_plant {
  float: right;
}

.eq_case_btn {
  margin-right: 12px;
  float: right;
  -webkit-box-shadow: rgb(0 0 0 / 24%) 2px 4px 24px 0px;
  box-shadow: rgb(0 0 0 / 24%) 2px 4px 24px 0px;
  border: 1px solid #45708d;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all;
  transform: scale(1);
}

.eq_case_btn:hover {
  border: 1px solid #45708d;
  filter: brightness(1.4);
  transition: 0.3s all;
  transform: scale(1.1);
}

.img_case_btn {
  width: 100%;
  max-width: 32px;
  height: auto;
}

.img_main {
  width: 100%;
  max-width: 980px;
  height: auto;
}

.eq_img {
  float: left;
  margin: 1%;
  clear: none;
  width: 48%;
  padding: 0.5em;
}

.eq_bg_fixed {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center 0%;
  background-size: cover;
  opacity: 1;
}

.eq_sect_bg {
  margin: auto;
  z-index: 1;
  position: fixed;
  top: 0%;
  overflow-y: scroll;
}

/* Title & Main Pic */

/* Plant Zone */

.eq_cate_sect {
  width: 96%;
  height: auto;
  position: relative;
  padding: 0px 2% 24px 2%;
  z-index: 110;
  max-width: 1200px;
  margin: auto;
}

.eq_plant_row {
  float: left;
  position: relative;
  padding: 20px 24px 4px 24px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0);
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.eq_12spps {
  width: 100%;
}

.eq_plant_title {
  width: 100%;
  text-align: center;
  margin: 0px auto;
}

.eq_plant_title_plant {
  width: 40%;
  float: left;
  font-size: 18px;
}

.eq_plant_title_cate {
  width: 40%;
  float: right;
  text-align: right;
}

.eq_plant_group {
  float: left;
  padding: 0% 0% 24px 0%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  max-height: 210px;
}

.eq_cate_title {
  width: 60%;
  color: #ffffff;
  font-weight: 500;
  font-size: 22px;
  text-transform: uppercase;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
  float: left;
  letter-spacing: 0.3px;
}

.eq_cate_sort {
  width: max-content;
  height: auto;
  float: right;
  display: inline-flex;
  margin-top: -6px;
  color: rgb(255 255 255);
}

.eq_cate_sort_btn {
  width: 64px;
  height: 32px;
  padding-top: 3px;
  background: rgba(0, 67, 112, 0);
  border: 1px solid #1f4864;
  text-align: center;
  filter: brightness(1);
  transition: 0.3s ease;
  cursor: pointer;
}

.eq_cate_sort_btn:hover {
  background: rgba(0, 67, 112, 1);
  border: 1px solid #1f4864;
  transition: 0.2s ease;
  filter: brightness(1.4);
}

.eq_cate_sort_btn.selected {
  background: rgba(0, 67, 112, 1);
  border: 1px solid #1f4864;
  transition: 0.2s ease;
  pointer-events: none;
}

.eq_cate_sort_btn.score {
  border-radius: 6px 0px 0px 6px;
}

.eq_cate_sort_btn.az {
  border-radius: 0px 6px 6px 0px;
}

.eq_cate_navi_col {
  width: 18.6%;
  min-height: 96px;
  float: left;
  text-align: left;
  padding: 10px 8px 8px 12px;
  margin: 0px 4px 8px 4px;
  border-radius: 6px;
}

.eq_plant_warn_col {
  background-color: rgba(30, 127, 193, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  transition: 0.2s ease;
  cursor: pointer;
  color: rgba(255, 207, 59, 1);
}
.eq_plant_warn_col0 {
  background-color: rgba(30, 127, 193, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  transition: 0.2s ease;
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.eq_plant_warn_col1 {
  background-color: rgba(30, 127, 193, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  transition: 0.2s ease;
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.eq_plant_warn_col2 {
  background-color: rgba(30, 127, 193, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  transition: 0.2s ease;
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.eq_plant_warn_col-1 {
  background-color: rgba(30, 127, 193, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  transition: 0.2s ease;
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.eq_plant_warn_col:hover {
  background: rgb(255 207 59) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgb(255 207 59);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_warn_col0:hover {
  background: rgba(144, 224, 149, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(144, 224, 149, 1);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_warn_col1:hover {
  background: rgba(255, 207, 59, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(255, 207, 59, 1);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_warn_col2:hover {
  background: rgba(255, 83, 83, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(255, 83, 83, 1);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_warn_col-1:hover {
  background: rgba(211, 211, 211, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(211, 211, 211, 1);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_warn_col.selected {
  background: rgb(255 207 59) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgb(255 207 59);
  color: rgb(0 0 0) !important;
  pointer-events: none;
}
.eq_plant_warn_col0.selected {
  background: rgba(144, 224, 149, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(144, 224, 149, 1);
  color: rgb(255, 255, 255)!important;
  pointer-events: none;
}
.eq_plant_warn_col1.selected {
  background: rgb(255 207 59) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgb(255 207 59);
  color:rgb(255, 255, 255) !important;
  pointer-events: none;
}
.eq_plant_warn_col2.selected {
  background: rgba(255, 83, 83, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(255, 83, 83, 1);
  color: rgb(255, 255, 255) !important;
  pointer-events: none;
}
.eq_plant_warn_col-1.selected {
  background: rgba(211, 211, 211, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(211, 211, 211, 1);
  color: rgb(255, 255, 255) !important;
  pointer-events: none;
}

.eq_plant_nor_col {
  background-color: rgba(30, 127, 193, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  transition: 0.2s ease;
  cursor: pointer;
  color: rgba(106, 185, 111, 1);
}

.eq_plant_nor_col0:hover {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_nor_col1:hover {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_nor_col2:hover {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_nor_col-1:hover {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  transition: 0.2s ease;
  color: rgb(0 0 0) !important;
}
.eq_plant_nor_col.selected {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  color: rgb(0 0 0) !important;
  pointer-events: none;
}
.eq_plant_nor_col0.selected {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  color: rgb(0 0 0) !important;
  pointer-events: none;
}
.eq_plant_nor_col1.selected {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  color: rgb(0 0 0) !important;
  pointer-events: none;
}
.eq_plant_nor_col2.selected {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  color: rgb(0 0 0) !important;
  pointer-events: none;
}
.eq_plant_nor_col-1.selected {
  background: rgba(106, 185, 111, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgba(106, 185, 111, 1);
  color: rgb(0 0 0) !important;
  pointer-events: none;
}
.eq_plant_normal {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px dotted rgba(8, 67, 112, 0.1);
  padding: 0px 12px;
  margin: auto;
  height: auto;
  position: relative;
  transition: 0.5s ease;
}

.eq_plant_ico {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 2px;
  cursor: pointer;
}

.eq_plant_normal:hover .eq_plant_ico {
  opacity: 1;
}

.eq_plant_img {
  position: absolute;
  text-align: center;
  padding: 0px 12px;
}

.plant_nor_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(106, 185, 111, 1);
  border-radius: 4px;
}

.eq_plant_normal:hover .plant_nor_overlay {
  opacity: 1;
}

.eq_plant_abnormal {
  border-radius: 4px;
  background-color: rgba(255, 56, 56, 0.8);
  border: 0px dotted rgba(8, 67, 112, 0);
  padding: 16px 12px;
  margin: auto;
  height: auto;
  position: relative;
  transition: 0.5s ease;
}

.plant_abnor_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(255, 56, 56, 0.95);
  cursor: pointer;
  border-radius: 4px;
}

.eq_plant_abnormal:hover .plant_abnor_overlay {
  opacity: 1;
}

.eq_plant_sub {
  margin: 4px auto 0px auto;
  height: auto;
}

.img_thumbs {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.img_equip {
  width: 100%;
  max-width: 56px;
  height: auto;
  margin: -4px auto 0px auto;
}

.eq_na {
  filter: opacity(20%);
}

.cate_container {
  width: 96%;
  height: auto;
  position: relative;
  padding: 6px 2% 0px 2%;
  z-index: 110;
  max-width: 1200px;
  margin: 0px auto 32px auto;
  border-radius: 12px;
  overflow-x: scroll;
}

.cate_hc_top {
  width: 100%;
  height: auto;
  float: left;
  padding: 0px 0px 12px 0px;
  border-bottom: 1px solid rgba(136, 136, 136, 1);
}

.cate_hc_bot {
  width: 100%;
  height: auto;
  float: left;
  padding: 8px 0px 0px 0px;
}

.cate_hc_bot_cont {
  width: 100%;
  height: auto;
  float: left;
  margin: 4px 0px;
}

.cate_hc_bot_btn {
  width: max-content;
  bottom: 24px;
  right: 24px;
  position: absolute;
  filter: brightness(1);
  transform: scale(1);
  transition: 0.3s all;
  cursor: pointer;
}

.cate_hc_bot_btn:hover {
  filter: brightness(1.4);
  transition: 0.2s all;
  transform: scale(1.1);
}

.cate_hc_col {
  width: 34%;
  min-width: 350px;
  height: 330px;
  float: left;
  position: relative;
  padding: 16px 24px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 8px;
}

.calend_ico {
  font-size: 20px;
  vertical-align: -3px;
  color: rgb(196 196 196);
  cursor: pointer;
  padding: 0px 0px 0px 2px;
  transition: 0.3s all;
}

.calend_ico:hover {
  color: rgba(77, 137, 192, 1);
  transition: 0.2s all;
}

.cate_hc_title {
  width: auto;
  height: auto;
  float: left;
  margin-top: 6px;
}

.cate_hc_chart {
  width: max-content;
  height: auto;
  float: right;
  margin: -85px -35px 0px 0px;
}

.cate_navi_col {
  width: 65%;
  height: auto;
  float: right;
  padding: 6px 0px 0px 30px;
}

.cate_ad_col {
  width: 100%;
  height: auto;
  float: left;
  padding: 32px;
}

.plant_status_col {
  width: 100%;
  height: auto;
  float: left;
  margin: 16px 0px 16px 0px;
}

.cate_date_opt {
  width: 100%;
  height: auto;
  float: left;
  margin: 24px 0px 0px 0px;
}

.cate_date_opt_txt {
  text-align: right;
  color: rgb(229 229 229);
  font-weight: 200;
  font-size: 14px;
}

.cate_date_opt_txt_day {
  /* text-align: right; */
  color: rgb(229 229 229);
  /* font-weight: 200;
  font-size: 14px;
  padding-top: 12px; */
} 


.cate_container_hc {
  width: 35%;
  height: auto;
  float: left;
  padding: 32px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.eq_details_container {
  width: 100%;
  min-width: 1200px;
  height: auto;
  position: relative;
  padding: 6px 0% 0px 0%;
  z-index: 110;
  max-width: 1200px;
  margin: 0px auto 0px auto;
  border-radius: 12px;
}

.ad_container_sect {
  width: 100%;
  height: auto;
  float: left;
  margin: 16px 0px 16px 0px;
}

.ad_container_l {
  width: 28%;
  height: auto;
  float: left;
  max-width: 278px;
}

.ad_hc_tools {
  width: 100%;
  min-width: 320px;
  height: 700px;
  margin: 0px auto 20px auto;
  display: block;
  background: transparent linear-gradient(90deg, #06304a00 0%, #164664 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  padding: 24px 9px 32px 26px;
}

.ad_hc_navlist {
  float: left;
  padding: 0% 0% 24px 0%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  max-height: 600px;
  border: 4px solid transparent;
}

.ad_hc_navlist::-webkit-scrollbar {
  width: 16px; /* กำหนดความกว้างของ scrollbar */
}

.ad_hc_navlist_menu {
  /* background: transparent; */
  /* color: #ffffff; */
  /* margin-left: -30px; */
}

.newSelectEquip {
  color: #ffffff;
  text-align: center;
  width: 230px;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid rgba(140, 140, 140, 0.35);
  color: #a6cfec;
  text-align: center;
  font-size: 12px;

}

.ad_hc_title {
  width: 100%;
  color: rgb(211 211 211);
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
  float: left;
  letter-spacing: 0.3px;
}

.ad_hc_title.alig_cen {
  padding: 0px 16px 0px 4px;
}

.ad_hc_title_menu {
  width: 100%;
  color: transparent;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
  float: left;
  letter-spacing: 0.3px;
}

.ad_hc_title_le {
  width: 100%;
  color: rgb(211 211 211);
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
  float: left;
  letter-spacing: 0.3px;
}
.ad_hc_title.left_le {
  padding: 0px 16px 0px 4px;
}

.ad_hc_title_le_dashbord_equip {
  width: 40%;
  color: rgb(15 15 15);
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
  letter-spacing: 0.3px;
}

.ad_hc_title_le_dashbord {
  width: 40%;
  color: rgb(15 15 15);
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
  letter-spacing: 0.3px;
}

.ad_hc_title_le_dashbord_3 {
  width: 53%;
  color: rgb(15 15 15);
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
  letter-spacing: 0.3px;
}

.ad_hc_title_le_dashbord_4 {
  width: 120%;
  color: rgb(15 15 15);
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 32px;
  margin: 0px 0px 12px 0px;
  letter-spacing: 0.3px;
  float: right;
  background: #d3d3d3;

}



.ad_hc_chart {
  width: 100%;
  height: auto;
  float: left;
  margin: -20px 0px 0px -27px;
}

.ad_container_r {
  width: 71%;
  height: auto;
  float: right;
}

.ad_calendar_r {
  width: 75%;
  height: auto;
  float: right;
  margin-top: 32px;
}

.ad_hc_header {
  width: 100%;
  height: auto;
  margin: auto;
  display: inline-flex;
  background: transparent linear-gradient(90deg, #1646648f 0%, #06304a00 100%)
    0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 32px 24px 40px 180px;
  position: relative;
  z-index: 15;
  overflow: hidden;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.ad_hc_header_title {
  width: max-content;
  height: auto;
  float: left;
  position: relative;
  z-index: 15;
  margin-right: 40px;
}

.ad_hc_header_equip {
  width: max-content;
  height: auto;
  float: left;
  position: relative;
  z-index: 15;
  margin-right: 40px;
}

.ad_hc_equip_img {
  position: absolute;
  top: -132%;
  left: -19%;
  width: 350px;
  height: 350px;
  -webkit-animation: spin 7s linear infinite;
  -moz-animation: spin 7s linear infinite;
  animation: spin 7s linear infinite;
}
.ad_hc_header_equip.ac_sensors {
  position: absolute;
  right: 0px;
  margin-right: 24px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ad_loadtype {
  width: 74%;
  height: auto;
  float: right;
  display: inline-flex;
  margin: 32px 0px;
  justify-content: right;
}

.ad_loadtype_txt {
  width: 100%;
  float: left;
  text-align: right;
  letter-spacing: 0.3px;
}

.ad_loadtype_txt.txt_l {
  text-align: left;
}

.ad_loadtype_box {
  width: 12%;
  height: auto;
  padding: 10px 10px 10px 14px;
  background: transparent linear-gradient(133deg, #4260755f 0%, #00000036 100%)
    0% 0% no-repeat padding-box;
  border: 1px solid #1131484d;
  border-radius: 10px;
  margin-left: 12px;
  color: rgb(255, 255, 255);
  max-width: 104px;
}

.ad_loadtype_head {
  width: 10%;
  height: auto;
  padding: 8px 10px 10px 14px;
  font-size: 18px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  min-width: 118px;
}
.ad_subject_title {
  width: 100%;
  margin: 24px auto 0px auto;
  max-width: 1200px;
  position: relative;
  padding-bottom: 8px;
}

.ad_sens_btn {
  width: 96%;
  height: auto;
  float: left;
  display: flex;
  padding: 6px 10px;
  color: #000000;
  border-radius: 5px;
  letter-spacing: 0.1px;
  margin: 3px 0px;
}

.ad_sens_btn_nav {
  background-color: rgba(30, 127, 193, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  transition: 0.2s ease;
  cursor: pointer;
  color: rgb(255 255 255);
}

.ad_sens_btn_nav:hover {
  /* background: rgb(255 207 59) 0% 0% no-repeat padding-box; */
  box-shadow: 0px 20px 20px #0000004a;
  border: 1px solid rgb(255 207 59);
  transition: 0.2s ease;
  color: #ffffff !important;
}

.ad_sens_btn_nav.selected {
  background: rgb(255 207 59) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000004a;
  border: 1px solid rgb(255 207 59);
  color: rgb(0 0 0) !important;
  /* pointer-events: none; */
}

.ad_sens_num {
  float: left;
  font-weight: 600;
  font-size: 16px;
  margin: 8px 12px 16px 2px;
  line-height: 8px;
}

.ad_sens_cont {
  float: left;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.ad_sens_sum_btn {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 24px;
}

.ad_sens_view_btn {
  width: 84px;
  height: 32px;
  padding: 3px 5px 3px 0px;
  color: #ffffff;
  background: #26638d;
  border: 1px solid #1f4864;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  filter: brightness(1);
  transition: 0.3s ease;
  cursor: pointer;
  border-radius: 5px;
  float: right;
  margin-right: 14px;
}

.ad_sens_view_btn:hover {
  background: rgba(0, 67, 112, 1);
  border: 1px solid #1f4864;
  transition: 0.2s ease;
  filter: brightness(1.4);
}

.ad_sens_chart_sum {
  width: 75%;
  height: auto;
  float: right;
  display: flex;
  flex-direction: column;
}

.ad_sens_chart_sum_anoly {
  width: 70%;
  height: auto;
  float: right;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.ad_sens_chart_sum_anoly_dashbord {
  width: 70%;
  height: auto;
  float: right;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.ad_sens_chart_sum_anoly_dashbord_3 {
  width: 70%;
  height: auto;
  float: right;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: absolute;
  margin-left: 57%;
}

.ad_sens_chart_sum_anoly_dashbord_impsensor {
  width: 75%;
  height: auto;
  float: right;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: absolute;
  margin-left: 30%;
}

.ad_sens_chart_sum_anoly_dashbord_Pi {
  width: 75%;
  height: auto;
  float: right;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: absolute;
  /* margin-left: 18%; */
}

.ad_sens_chart_sect {
  width: 100%;
  height: auto;
  float: right;
  position: relative;
  padding: 24px 2%;
  margin-bottom: 24px;
  background: transparent linear-gradient(351deg, #06304a67 0%, #16466483 100%)
    0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.ad_sens_chart_title {
  width: 68%;
  height: auto;
  padding: 0% 0%;
  position: relative;
  color: rgba(255, 255, 255, 1);
  font-weight: 200;
  letter-spacing: 0.2px;
  float: left;
}

.ad_sens_chart_num {
  width: 30%;
  height: auto;
  padding: 0% 4px 0% 0%;
  position: relative;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.2px;
  float: right;
  text-align: right;
  align-items: center;
  display: flex;
  justify-content: right;
}

.cate_detect_opt_txt {
  text-align: right;
  color: rgb(229 229 229);
  font-weight: 200;
  font-size: 14px;
  vertical-align: 6px;
  padding-right: 4px;
}

.ad_sens_chart_col {
  width: 32%;
  position: relative;
  float: left;
  margin: 0.66%;
}

.ad_sens_chart_result {
  width: 100%;
  float: left;
}

.ad_sens_chart_img {
  padding: 6px 8px;
  border-radius: 10px;
  background-color: rgb(255 255 255);
}

.chart_cap_img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.data_container {
  width: 96%;
  height: auto;
  position: relative;
  padding: 16px 2% 0px 2%;
  z-index: 110;
}

.pla_s_title {
  width: 11%;
  float: left;
  position: relative;
  padding-bottom: 16px;
  margin-top: -6px;
  text-align: right;
}

.pla_s_charts {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  float: right;
  position: relative;
  padding: 24px 24px 24px 24px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0.95);
}
.pla_s_charts h1 {
  margin: 6px;
}

.pla_s_charts_tank {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  float: right;
  position: relative;
  padding: 24px 24px 24px 24px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0.95);
}

.pla_s_charts_tank_dsahbord {
  height: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57%;
  float: right;
  position: relative;
  /* padding: 24px 24px 24px 24px; */
  border-radius: 6px;
  margin-left: -31px;
  background-color: rgba(245, 251, 255, 0.95);
}

.pla_s_charts_tank_dsahbord_2 {
  height: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57%;
  float: right;
  position: relative;
  /* padding: 24px 24px 24px 24px; */
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0.95);
}


.pla_s_charts_tank_dsahbord_3 {
  height: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  float: right;
  position: relative;
  /* padding: 24px 24px 24px 24px; */
  border-radius: 6px;
  margin-left: -79px;
  background-color: rgba(245, 251, 255, 0.95);
}


.pla_s_charts_tank_dsahbord_4 {
  height: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  float: right;
  position: relative;
  /* padding: 24px 24px 24px 24px; */
  border-radius: 6px;
  margin-left: -79px;
  background-color: rgba(245, 251, 255, 0.95);
}

.data_content_title {
  width: 100%;
  margin: 0px auto 12px auto;
  max-width: 1200px;
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.data_subject_title {
  width: 100%;
  margin: 24px auto 0px auto;
  max-width: 1200px;
  position: relative;
  padding-bottom: 24px;
}
.data_content_issue {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  position: relative;
  padding: 24px 24px 32px 24px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0.95);
  min-width: 1122px;
}
.data_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 400px;
  margin: auto;
  max-width: 1200px;
  position: relative;
  padding: 24px 24px 32px 24px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0.95);
}

.contentStyle {
  padding: 0 35px 20px 0;
  background-color: rgba(1, 1, 1, 0.05);
  border-radius: 4;
  font-size: 20;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}

.ant-menu-title-content {
  text-overflow: unset !important;
}

.spinMenu{
  text-align: center;
  margin-left: 50px;
  margin-top: 50px;
  padding-left: 50px;
  padding-top: 50px;
}

.ant-menu-sub 
{
  margin-left: -25px;
  overflow-x: scroll;
  width: auto;
}

.data_content.h_mockup {
  min-height: 440px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(170, 170, 170, 0.2);
  border-radius: 10px;
}

.ant-btn-round .ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 13px;
  border-radius: 40px;
  margin-bottom: 15px;
  background-color: #90E195;
  border-color: #90E195;
  float: right;
  width: 10dvi;
}

.exportExcel {
 margin-top: -20px;
}

/* Plant Zone */

@media only screen and (max-width: 1300px) {
  .eq_container {
    min-width: 1024px;
  }
  .eq_12spps {
    min-width: 1122px;
  }
  .data_content {
    min-width: 1122px;
  }
  .eq_cate_sect {
    min-width: 1024px;
  }
  .eq_plant_col {
    float: left;
    text-align: center;
  }
  .ad_container_r {
    width: 70%;
    height: auto;
    float: right;
    margin-left: 2%;
  }
  .ad_calendar_r {
    width: 70%;
    margin-left: 2%;
  }
  .ad_sens_chart_sum {
    width: 70%;
    margin-left: 2%;
  }

  .ad_sens_chart_sum_amoly {
    width: 70%;
    margin-left: 2%;
  }
  .ad_hc_header {
    padding: 16px 24px 24px 96px;
  }
}

@media only screen and (max-width: 1240px) {
  .eq_sum_space {
    max-width: 124px;
    min-width: 124px;
  }
}
@media only screen and (max-width: 1167px) {
  .ad_loadtype {
    width: 70%;
  }
}

@media only screen and (max-width: 1080px) {
  .eq_sum_space {
    max-width: 120px;
    min-width: 120px;
    padding: 10px 8px 0px 12px;
  }
}
