.close {
  float: right;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  color: rgb(66, 66, 66);
  margin-top: -20px;
  margin-right: -20px;
  transition: 0.3s all;
}

.close:focus,
.close:hover {
  color: rgba(0, 67, 112, 1);
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s all;
  transform: scale(1.2);
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  position: relative;
  right: 18px;
  top: 24px;
}

.modal-open {
  overflow: visible;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 !important;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgb(0 0 0 / 50%);
  transition: 0.3s ease-out;
  backdrop-filter: saturate(160%) blur(8px);
  -webkit-backdrop-filter: saturate(160%) blur(8px);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-header {
  min-height: 16.43px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal.fade .ml-modal-body {
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  top: -850px;
}

.modal.in .ml-modal-body {
  top: 50%;
  transform: translate(-50%, -53%);
}

/* Slide Up from Bottom */

.ml-modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -53%);
  padding: 16px 24px 32px 24px;
  min-width: 480px;
  max-width: 480px;
  background-color: rgb(247, 247, 247);
  width: auto;
  height: auto;
  border-radius: 6px;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal_close {
  position: absolute;
  top: -10px;
  right: -10px;
  color: rgb(226, 0, 0);
  font-size: 24px;
  text-shadow: 0 0px 0 rgba(0, 0, 0, 0);
  -webkit-transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
}

.modal_book_cont {
  width: 100%;
  height: auto;
  text-align: center;
  float: left;
  margin: auto;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 28px;
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}
