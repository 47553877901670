@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@font-face {
  font-family: "sarabun";
  src: url(../font/THSarabunNew.eot);
  src: url(../font/THSarabunNew.eot?#iefix) format("embedded-opentype"),
    url(../font/THSarabunNew.otf),
    url(../font/THSarabunNew.ttf) format("truetype"),
    url(../font/THSarabunNew.woff) format("woff");
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: auto;
  overflow-x: hidden !important;
  background-color: transparent;
  letter-spacing: -0.2px;
  -webkit-overflow-scrolling: touch;
}

body.body {
  margin: 0;
  background: rgb(0, 59, 98);
  background: linear-gradient(0deg, rgb(13 45 65) 0%, rgb(11 38 56) 100%);
  font-family: "Poppins", sans-serif;
  color: #222;
  position: relative;
  font-size: 15px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

div,
img,
p,
button,
input,
select,
textarea,
a {
  box-sizing: border-box;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

a {
  text-decoration: none;
}

.issue_date_r a:hover {
  color: rgba(106, 185, 111, 1);
  transition: 0.3s linear;
}

a:-webkit-any-link {
  cursor: pointer;
  color: unset;
}

i {
  padding: 4px;
  width: 56px;
  height: 56px;
}

.i-ico {
  font-size: 16px;
  color: rgba(36, 36, 36, 1);
}

.ic-ico {
  font-size: 15px;
  color: rgba(83, 83, 83, 1);
  vertical-align: -1px;
}

.ib-ico {
  font-size: 16px;
  color: rgba(0, 67, 112, 1);
}

.ig-ico {
  font-size: 16px;
  color: rgba(106, 185, 111, 1);
}

.ico_clogo {
  font-size: 24px;
  margin-left: -5px;
}

.i-2 {
  vertical-align: -2px;
}

.signin_logo {
  font-size: 32px;
  margin-left: -5px;
}

.ico_flogo {
  font-size: 22px;
  margin-left: -5px;
}
.eq_breadcum_tar {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 300;
  letter-spacing: 0.3px;
  font-size: 13px;
}

.eq_breadcum::before {
  padding: 8px;
  color: rgba(163, 163, 163, 1);
  content: "\007C";
  font-weight: 200;
  vertical-align: -0.5px;
}

.co_off {
  color: rgba(163, 163, 163, 1) !important;
}

.co_abnor {
  color: rgba(255, 83, 83, 1) !important;
}

.co_warning {
  color: rgba(255, 207, 59, 1) !important;
}
.co_alert {
  color: rgba(255, 83, 83, 1) !important;
}
.co_warn_sta {
  /* color: rgba(255, 207, 59, 1) !important;
  font-weight: 600; */
  color: rgb(233 241 245) !important;
  font-weight: 500;
  flex: none;
  margin-right: 100px;
}

.co_run {
  color: rgba(144, 224, 149, 1) !important;
}

.co_result {
  color: rgb(78 200 216) !important;
  font-weight: 500;
}

.co_wh {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 400;
}

.co_gre {
  color: rgba(106, 185, 111, 1) !important;
  font-weight: 500;
}

.menu-ico {
  font-size: 20px;
  color: rgba(224, 224, 224, 1);
  transition: all 0.2s;
  cursor: pointer;
  padding: 0px !important;
}

.menu-ico:hover {
  color: rgba(106, 185, 111, 1);
  transition: all 0.5s;
}

.if-ico {
  font-size: 16px;
  color: rgba(224, 224, 224, 1);
  transition: all 0.2s;
  cursor: pointer;
}

.if-ico:hover {
  color: rgba(106, 185, 111, 1);
  transition: all 0.5s;
}

.ico_btn {
  vertical-align: -3px;
  font-size: 18px;
}

.elec_ico {
  width: 12px;
  height: auto;
  cursor: pointer;
  margin-right: 4px;
}

.gre_color {
  color: rgba(106, 185, 111, 1);
}
.image {
  display: block;
}

img {
  vertical-align: middle;
  border-style: none;
  display: block;
}

.img_shadows {
  -webkit-filter: drop-shadow(2px 2px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}

.img_a {
  transform: scale(1);
  filter: brightness(100%);
  transition: all 0.5s;
}

.img_a:hover {
  transform: scale(1.03);
  filter: brightness(120%);
  transition: all 0.3s;
}

.image_slide {
  padding: 36%;
  border-radius: 8px;
}

.img_main {
  width: 100%;
  max-width: 920px;
  height: auto;
}

.img_equip_pic {
  width: 100%;
  max-width: 680px;
  height: auto;
}

.img_unsup {
  width: 100%;
  max-width: 117px;
  height: auto;
  margin: 0px auto 24px auto;
}

.img_analytic {
  width: 100%;
  max-width: 28px;
  height: auto;
}

.img_hs_chart {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.img_adhs_chart {
  width: 100%;
  max-width: 210px;
  height: auto;
  margin: auto;
}

.prof_htxt {
  color: rgba(208, 208, 208, 1);
  font-weight: 500;
  font-size: 14px;
}

.run_sta_htxt {
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 15px;
  line-height: 32px;
  margin: 5px;
  padding: 5px 0 0 20px;
}

.prof_stxt {
  color: rgba(228, 123, 75, 1);
  font-weight: 300;
  font-size: 12px;
}

.txt_plant_name {
  color: rgba(54, 54, 54, 1);
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.txt_plant_name_dashbord {
  color: rgba(54, 54, 54, 1);
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.txt_equip_name {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin: 0;
  line-height: 20px;
  filter: brightness(5);
  height: 50px;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}

.txt_equip_number {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  margin: 8px 0px 16px 0px;
  line-height: 8px;
}

.txt_plant_status {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}

.nor_status {
  color: rgba(106, 185, 111, 1);
}

.abnor_status {
  color: rgba(255, 83, 83, 1);
}

.txt_h1txt {
  color: rgba(208, 208, 208, 1);
  font-weight: 600;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  margin: 0;
}

.txt_h1txt2 {
  color: rgba(208, 208, 208, 1);
  font-weight: 600;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 32px;
  margin: 0;
}

.txt_h1txt2_sign {
  color: rgba(0, 67, 112, 1);
  font-weight: 600;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 0px;
  margin: 0;
}

.txt_equip_title {
  color: rgba(208, 208, 208, 1);
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 16px;
  margin: 0;
}

.txt_equip_plant {
  color: #ffffff;
  font-weight: 500;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin: 0;
}

.txt_equip_acc {
  color: #ffffff;
  font-weight: 500;
  font-size: 28px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin: 0;
}

.txt_h2txt {
  color: rgba(208, 208, 208, 1);
  font-weight: 200;
  font-size: 20px;
  text-transform: capitalize;
  line-height: 24px;
  margin: 0;
}

.txt_h2txt_result {
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 24px;
  margin: 0;
}

.txt_h2txt_sign {
  color: rgba(106, 185, 111, 1);
  font-weight: 300;
  font-size: 18px;
  text-transform: capitalize;
  line-height: 24px;
  margin: 0;
}

.txt_h1txt2_plan {
  color: rgba(106, 185, 111, 1);
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  line-height: 32px;
  margin: 0;
}

.txt_h3txt {
  color: rgba(228, 123, 75, 1);
  font-weight: 200;
  font-size: 20px;
  text-transform: capitalize;
  line-height: 20px;
  margin: 0;
}

.txt_h3txt_plan {
  color: rgb(255 255 255);
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  line-height: 32px;
  margin: 0;
  text-align: right;
  letter-spacing: 0.5px;
}

.txt_h3txt_chart_title {
  color: rgb(255 255 255);
  font-weight: 400;
  font-size: 20px;
  /* text-transform: capitalize; */
  line-height: 32px;
  margin: 0;
  text-align: right;
  /* letter-spacing: 0.5px; */
}

.txt_h3txt_hs {
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 32px;
  margin: 0;
  text-align: right;
  letter-spacing: 0.5px;
}

.txt_hs_title {
  color: rgba(54, 54, 54, 1);
  font-weight: 200;
  font-size: 14px;
  text-transform: capitalize;
}

.txt_hs_subject {
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
}

.txt_h3txt_head {
  color: rgb(255 255 255);
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin: 0;
}

.txt_h3txt_wh {
  color: rgba(208, 208, 208, 1);
  font-weight: 300;
  font-size: 17px;
  text-transform: capitalize;
  line-height: 20px;
  margin: 0;
}

.txt_h4txt {
  color: rgba(54, 54, 54, 1);
  font-weight: 700;
  font-size: 25px;
}

.txt_h4txt_sign {
  color: rgba(74, 74, 74, 1);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.txt_h4txt_w {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  font-size: 25px;
  text-transform: uppercase;
}

.txt_h4txt_plant {
  color: rgb(168 210 239);
  font-weight: 200;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

.txt_h5txt_body {
  color: rgba(54, 54, 54, 0.85);
  font-weight: 200;
  font-size: 13px;
  line-height: 18px;
}

.txt_h5txt_w {
  color: rgba(208, 208, 208, 1);
  font-weight: 200;
  font-size: 15px;
}

.txt_h5txt_plant {
  color: rgba(106, 185, 111, 1);
  font-weight: 600;
  text-transform: uppercase;
}

.txt_h5txt_title {
  color: rgba(177, 177, 177, 1);
  font-weight: 200;
  font-size: 14px;
}
.txt_h5lt_result {
  color: rgba(168, 210, 239, 1);
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
}
.txt_h5lt_mw {
  color: rgba(211, 211, 211, 1);
  font-weight: 400;
  font-size: 12px;
}
.txt_h5ad_count {
  color: rgba(211, 211, 211, 1);
  font-weight: 200;
  font-size: 12px;
}
.txt_h5lt_num {
  color: rgba(211, 211, 211, 1);
  font-weight: 200;
  font-size: 12px;
}
.txt_h5lt_percent {
  color: rgba(168, 210, 239, 1);
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}
.txt_th1 {
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 67, 112, 1);
  text-transform: uppercase;
}

.txt_td1 {
  font-size: 14px;
  font-weight: 300;
  color: rgba(54, 54, 54, 1);
}

.txt_modal_title {
  font-size: 14px;
  font-weight: 200;
  color: rgb(229, 229, 229);
  text-transform: capitalize;
}

.txt_modal_sub {
  font-size: 16px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}

iframe {
  border-radius: 8px !important;
}

.w3-animate-opacity {
  animation: opac 1s;
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.n_support_bg {
  display: none;
}

/* Drop Menu */

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);
  width: 100vw;
  overflow: auto;
  border-radius: 6px;
  box-shadow: 0px 11px 39px -9px rgb(0 0 0 / 30%);
  z-index: 1;
  color: #ffffff;
  height: auto;
  top: 100%;
  right: 0px;
  transform: translate(0%, 8px);
  transition: 0.3s ease;
  max-width: 578px;
  padding: 16px 8px 8px 8px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}
.layout-noti {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1rem;
}
.layout-noti > h2 {
  text-align: start;
  padding: 0;
  margin: 0;
  color: #363636;
}

.plant_selected {
  opacity: 1 !important;
}

.tab {
  overflow: hidden;
  position: relative;
  border: 0px solid #ccc;
}

/* Style the buttons inside the tab */

.tab button {
  background-color: inherit;
  float: right;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6px 8px;
  transition: 0.3s;
  font-size: 14px;
  border: 1px dotted rgba(54, 54, 54, 0.2);
  border-radius: 4px;
  margin-left: 4px;
  font-weight: 400;
  color: black;
}

/* Change background color of buttons on hover */

.tab button:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(106, 185, 111, 1);
  border: 1px solid rgba(236, 239, 243, 1);
}

/* Create an active/current tablink class */

.tab button.active {
  color: #ffffff;
  background-color: rgba(106, 185, 111, 1);
  border: 1px solid rgb(255 255 255);
}

/* Style and show the tab content */

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 0px solid #ccc;
  border-top: none;
  color: #000000;
  overflow-x: initial;
  overflow-wrap: break-word;
}
.show {
  display: block;
}

/* Modal */

.ml-modal-body h1 {
  font-size: 4rem;
}

.ml-modal-body p {
  margin: 0 0 1.62rem 0;
  line-height: 1.62;
  font-weight: 300;
  font-size: 1.62rem;
  color: #666;
}

.popup-button {
  margin-top: 70px;
}

/* Profile */

.ml_menu_col {
  width: auto;
  height: auto;
  float: right;
  padding-right: 24px;
}

.img_nav {
  width: 100%;
  height: auto;
  max-width: 32px;
}

.btn_status {
  width: 100%;
  height: auto;
  max-width: 20px;
  margin: auto;
}

.btn_a {
  cursor: pointer;
}

.profile_col {
  width: auto;
  height: auto;
  float: right;
  display: contents;
}

.ml_prof_name {
  width: auto;
  height: auto;
  float: right;
  margin-top: -1px;
  padding-right: 4px;
  text-align: right;
  letter-spacing: 0.2px;
}

.ml_prof_avatar {
  width: 30%;
  max-width: 48px;
  height: auto;
  float: right;
  text-align: right;
}

.round_frame_avar {
  width: 25%;
  min-width: 40px;
  max-width: 40px;
  border-radius: 1000px;
  height: 40px;
  border: 0px solid rgba(216, 216, 216, 0.5);
  margin-right: 0px;
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.profile_avatar_img {
  width: auto;
  height: auto;
  max-width: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Profile */

.txt-rd {
  color: rgba(255, 83, 83, 1);
}

.txt-opt {
  color: rgb(106 185 111 / 70%);
  font-size: 12px;
  padding-left: 4px;
}

.btn_h {
  margin-top: 16px;
}

.more_btn {
  float: left;
  margin-top: 56px;
}

.g_more_btn a {
  padding: 12px 16px;
  -webkit-text-stroke-width: 0.2px !important;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(106, 185, 111, 1);
  border-radius: 4px;
  z-index: 20;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-top: 32px;
}

.g_more_btn a:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 67, 112, 1);
  transition: 0.2s ease-in-out;
}

.arrow_btn a {
  padding: 8px 0px 8px 0px;
  width: 40px;
  text-align: left;
  cursor: pointer;
  font-size: 20px;
  -webkit-text-stroke-width: 0px;
  color: rgba(106, 185, 111, 1);
  transition: all 0.2s;
  position: relative;
  left: 0px;
}

.arrow_btn a:hover {
  -webkit-text-stroke-width: 1.2px;
  color: rgba(0, 67, 112, 1);
  padding: 8px 0px 8px 0px;
  transition: all 0.3s;
  position: relative;
  left: 6px;
}

.arrow_btn2 a {
  padding: 8px 0px 8px 0px;
  width: 40px;
  text-align: left;
  cursor: pointer;
  font-size: 20px;
  -webkit-text-stroke-width: 0px;
  color: rgba(106, 185, 111, 1);
  transition: all 0.2s;
  position: relative;
  left: 0px;
}

.arrow_btn2 a:hover {
  -webkit-text-stroke-width: 1.2px;
  color: rgba(255, 255, 255, 0.8);
  padding: 8px 0px 8px 0px;
  transition: all 0.3s;
  position: relative;
  left: 6px;
}

.btn_space {
  margin-top: 24px;
}

.float_posi {
  position: fixed;
  bottom: 12px;
  right: 15px;
  width: 48px;
  height: auto;
  z-index: 99999999999;
}

.float_i1 {
  margin: 8px 0px;
  cursor: pointer;
  border-radius: 200px;
  background-color: rgba(106, 185, 111, 0.8);
  -webkit-text-stroke-width: 0.8px !important;
  box-shadow: 0px 8px 9px 0px rgb(96 94 94 / 17%);
  -webkit-backdrop-filter: saturate(400%) blur(8px);
  backdrop-filter: saturate(400%) blur(8px);
  transform: scale(1);
  transition: all 0.5s;
}

.float_i1:hover {
  margin: 8px 0px;
  cursor: pointer;
  border-radius: 200px;
  background-color: rgba(106, 185, 111, 1);
  -webkit-text-stroke-width: 0.8px !important;
  box-shadow: 0px 0px 0px 0px rgb(96 94 94 / 17%);
  -webkit-backdrop-filter: saturate(400%) blur(0px);
  backdrop-filter: saturate(400%) blur(0px);
  transform: scale(1.1);
  transition: all 0.3s;
}

.float_i2 {
  margin: 8px 0px;
  cursor: pointer;
  border-radius: 200px;
  background-color: rgba(26, 157, 255, 0.8);
  -webkit-text-stroke-width: 0.8px !important;
  box-shadow: 0px 8px 9px 0px rgb(96 94 94 / 17%);
  -webkit-backdrop-filter: saturate(400%) blur(8px);
  backdrop-filter: saturate(400%) blur(8px);
  transform: scale(1);
  transition: all 0.5s;
}

.float_i2:hover {
  margin: 8px 0px;
  cursor: pointer;
  border-radius: 200px;
  background-color: rgba(26, 157, 255, 0.8);
  -webkit-text-stroke-width: 0.8px !important;
  box-shadow: 0px 0px 0px 0px rgb(96 94 94 / 17%);
  -webkit-backdrop-filter: saturate(400%) blur(0px);
  backdrop-filter: saturate(400%) blur(0px);
  transform: scale(1.1);
  transition: all 0.3s;
}

.float_ico {
  width: 48px;
  height: 48px;
  cursor: pointer;
  padding: 10px;
}

.float_ico2 {
  width: 48px;
  height: 48px;
  cursor: pointer;
  padding: 10px 8px 10px 12px;
}

.bottom_48 {
  margin-bottom: 48px;
}

.top16 {
  margin-top: 16px;
}

.txt-noti-limited {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  float: left;
}

.footer_add {
  color: #fff;
  cursor: pointer;
  transition: all 0.7s ease 0s;
  margin-top: 1px;
}

.footer_add_nav {
  color: #484848;
  cursor: pointer;
  transition: all 0.7s ease 0s;
  margin-top: 1px;
}

.SmoothScroll {
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.contain_dev {
  width: 100vw;
  overflow-x: hidden !important;
}

.contain_dev {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

p {
  margin: 0;
}

.r_side {
  float: right !important;
}

.txt_cent {
  text-align: center !important;
}

.txt_b {
  font-weight: 600 !important;
}

.txt_whyb {
  font-weight: 600 !important;
  color: rgb(0 67 112);
}

.txtind li {
  list-style-position: outside !important;
}

ul.txtlist {
  list-style-type: disc;
}

ul.txtlist li {
  margin-bottom: 8px;
}

ul.qacont {
  list-style-position: inside;
  padding-left: 4px;
}

li.qalist_q {
  list-style-type: "Q : ";
  margin-bottom: 4px;
  color: rgba(26, 157, 255, 1);
  font-weight: 600;
  font-size: 17px;
  -webkit-text-stroke-width: 0.3px;
  letter-spacing: 0.5px;
}

li.qalist_a {
  list-style-type: "A : ";
  margin-bottom: 8px;
  color: rgba(106, 185, 111, 1);
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

.footer_add {
  position: absolute;
  right: 0px;
  top: 0px;
}

.footer_add_nav {
  position: absolute;
  right: 0px;
  top: 0px;
}

.foolink a {
  color: rgba(224, 224, 224, 1);
  font-weight: 400;
  transition: all 0.2s;
  cursor: pointer;
  line-height: 26px;
}

.foolink a:hover {
  color: rgba(106, 185, 111, 1);
  transition: all 0.5s;
}

.tooltip {
  position: relative;
  transition: all 0.5s ease 0s;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: rgb(0 0 0 / 95%);
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  padding: 3px 6px;
  position: absolute;
  opacity: 0;
  bottom: 130%;
  left: 40%;
  z-index: 1;
  -webkit-box-shadow: rgb(0 0 0 / 8%) 0px 0px 10px 5px;
  box-shadow: rgb(0 0 0 / 9%) 0px 0px 10px 3px;
  transition: all 0.2s ease 0s;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 12px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  transition: all 0.5s ease 0s;
  opacity: 1;
}

.modal_title {
  font-size: 24px;
  line-height: 44px;
  color: rgb(0, 0, 0);
  margin-top: 0px;
  font-weight: 700;
  text-transform: uppercase;
}

.modal_info {
  padding: 12px 20px;
  border-radius: 5px;
  margin-top: 12px;
  color: #ffffff;
  background-color: rgba(0, 67, 112, 1);
  background-image: url(../img/modal_info_bg.png);
  background-repeat: no-repeat;
  background-position: 50% 65%;
  background-size: cover;
}

.modal_info_l {
  width: 60%;
  height: auto;
  display: inline-flex;
}

.modal_info_plant {
  width: 30%;
  height: auto;
}

.modal_info_equip {
  width: max-content;
  height: auto;
  float: right;
}

.modal_info_r {
  width: 35%;
  height: auto;
  float: right;
}

.f_mar20 {
  margin-right: 20px;
}

.field_l {
  float: left;
}

.field_r {
  float: right;
}

.modal_formsub {
  width: 100%;
  height: auto;
}

.modal_input1 {
  display: flex;
  width: 47%;
  height: auto;
  padding: 12px;
  border-radius: 4px;
  border: 1px dashed rgb(124 124 124);
  margin-top: 24px;
}

.modal_input2 {
  width: 100%;
  height: auto;
  padding: 12px;
  border-radius: 4px;
  border: 1px dashed rgb(124 124 124);
  margin-top: 20px;
  float: left;
}

.modal_submit {
  width: 100%;
  height: auto;
  padding: 12px 0px 12px 12px;
  margin-top: 8px;
  float: left;
}

.modal_btn {
  width: max-content;
  height: auto;
  display: inline-flex;
  float: right;
}

.modal_btn_disabled {
  font-size: 15px;
  font-weight: 400;
  padding: 4px 8px 6px 8px;
  text-align: center;
  white-space: nowrap;
  color: rgb(124 124 124);
  min-width: 64px;
  margin-left: 10px;
  border-radius: 4px;
}

.modal_btn_save {
  font-size: 15px;
  font-weight: 400;
  padding: 4px 8px 6px 8px;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(0, 67, 112, 1);
  border: 1px solid rgba(0, 67, 112, 1);
  color: #fff;
  border-radius: 4px;
  min-width: 64px;
  margin-left: 10px;
  transition: 0.3s all;
  cursor: pointer;
}

.modal_btn_save:hover {
  background-color: rgba(106, 185, 111, 1);
  border: 1px solid rgb(247, 247, 247);
  transition: 0.2s all;
}

.modal_btn_cancel {
  font-size: 15px;
  font-weight: 400;
  padding: 4px 8px 6px 8px;
  text-align: center;
  white-space: nowrap;
  color: rgb(124 124 124);
  border: 1px solid rgb(124 124 124);
  background-color: rgb(247, 247, 247);
  border-radius: 4px;
  min-width: 64px;
  transition: 0.3s all;
  cursor: pointer;
}

.modal_btn_cancel:hover {
  color: rgba(74, 74, 74, 1);
  background-color: #dedede;
  border: 1px solid rgb(247, 247, 247);
  transition: 0.2s all;
}

.modal_table {
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid rgb(214 214 214);
  margin-top: 0px;
} 

.modal_lastup {
  width: 100%;
  height: auto; 
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgb(214 214 214);
  margin-top: 16px;
  float: left;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal_lastup_title {
  cursor: default;
  top: -27px;
  position: relative;
  width: fit-content;
  padding: 4px;
  background-color: rgb(247 247 247);
  border-radius: 4px;
  margin-left: -4px;
  color: rgba(74, 74, 74, 1);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}

.modal_lastup_cont {
  width: 100%;
  height: auto;
  float: left;
  color: rgb(124 124 124);
  font-size: 14px;
  font-weight: 200;
  margin-top: -32px;
  padding: 8px 12px 4px 12px;
}

.modal_lastup_cont_l {
  width: 52%;
  height: auto;
  float: left;
}

.modal_lastup_cont_r {
  width: 42.99%;
  height: auto;
  display: inline-flex;
  float: right;
}

.modal_lastup_date {
  width: 65%;
  height: auto;
}

.modal_lastup_time {
  width: 30%;
  height: auto;
}

.modal_lastup_list {
  width: 100%;
  height: auto;
  float: left;
  color: rgb(92 92 92);
  font-size: 14px;
  font-weight: 400;
  background-color: rgb(237 237 237);
  padding: 4px 12px;
  border-radius: 4px;
}

.modal_lastup_list_cont {
  width: 100%;
  height: auto;
  float: left;
  padding: 4px 0px;
  color: rgb(92 92 92);
  font-size: 13px;
  font-weight: 400;
}

.list_bor:nth-last-of-type(n + 2) {
  border-bottom: 1px solid #ffffff;
}

.top_ofi_bg {
  width: 100%;
  margin: auto;
  padding: 8px 2% 9px 2%;
  position: relative;
  background-color: rgba(0, 67, 112, 1);
  z-index: 120;
}

.top_content_ofi {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  position: relative;
}

.top_logo_ofi {
  width: max-content;
  height: auto;
  float: left;
  margin-right: 20px;
  display: none;
}

.gulf_logo {
  width: 64px;
  height: auto;
  cursor: pointer;
}

.gulf_success_logo {
  width: 80px;
  height: auto;
  cursor: pointer;
  margin: auto;
}

.gulf_footer_logo {
  width: 120px;
  height: auto;
  cursor: pointer;
}

.top_menu_ofi {
  width: 82%;
  height: auto;
  float: left;
  display: block;
}

.top_content_bg {
  width: 100%;
  margin: auto;
  padding: 40px 2% 15px 2%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
}

.top_content {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  position: relative;
  display: inline;
  align-items: center;
}

.top_logo {
  width: max-content;
  height: auto;
  float: left;
}

.gulf1_logo {
  width: 128px;
  height: auto;
  cursor: pointer;
}

.top_menu {
  width: 100%;
  height: auto;
  float: right;
  display: contents;
}

.top_menu_res {
  width: 100%;
  height: auto;
  float: right;
  display: none;
}

.top_gmenu_res {
  width: 30%;
  height: auto;
  float: right;
  display: none;
}

ul.ofi_menu {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 12px;
}

ul.ofi_menu li {
  display: inline-block;
  padding: 0 16px 0px 0px;
  user-select: none;
  margin-bottom: 0px;
  transition: 0.3s all;
  letter-spacing: 0.2px;
  font-size: 12px;
}

.ofi_link a {
  color: rgba(255, 255, 255, 1);
  transition: all 0.2s ease 0s;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}

.ofi_link a:hover {
  color: rgba(159, 239, 164, 1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
}

ul.gulf1_menu {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 16px;
  text-align: right;
}

ul.gulf1_menu li {
  display: inline-block;
  padding: 0 0px 0 24px;
  user-select: none;
  margin-bottom: 0px;
  transition: 0.3s all;
  letter-spacing: -0.1px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.gulf1_link a {
  color: rgba(36, 36, 36, 1);
  transition: all 0.2s ease 0s;
  cursor: pointer;
}

.gulf1_link a:hover {
  color: rgba(106, 185, 111, 1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
}

.nav_top_menu {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.nav_top_ico {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 2px;
}

.nav_top_menu:hover .nav_top_ico {
  opacity: 1;
}

.nav_top_img {
  position: absolute;
  text-align: center;
}

#value {
  transition: all 0.5s ease 0s;
}

.value {
  transition: all 0.5s ease 0s;
  color: rgba(26, 157, 255, 1);
  font-weight: 600;
}

.value_s {
  display: inline-flex;
  width: 20px;
}

.value2_s {
  display: inline-flex;
  width: 27px;
}

.value3_s {
  display: inline-flex;
  width: 24px;
}

.value_num {
  display: inline-flex;
  width: 68px;
}

.freeprivacypolicy-com---palette-light {
  background-color: rgba(255, 255, 255, 0.7) !important;
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0px 5px 3px 0px !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 2px 0px !important;
  -webkit-backdrop-filter: saturate(340%) blur(20px) !important;
  backdrop-filter: saturate(340%) blur(20px) !important;
}

/* Start < Input Text > Start < Input Text > Start < Input Text > Start < Input Text > Start < Input Text > */

.form_row {
  border-radius: 2px;
}

label {
  cursor: default;
  top: -24px;
  position: relative;
  width: fit-content;
  padding: 4px;
  background-color: rgb(247 247 247);
  border-radius: 4px;
  margin-left: -4px;
  color: rgba(22, 60, 87, 1);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

input[type="text"],
select {
  width: 24%;
  max-height: 36px;
  min-height: 36px;
  padding: 8px;
  display: inline-block;
  border: 1px solid rgb(255 255 255 / 20%);
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 16px;
  color: rgb(127 198 246);
  background-color: rgb(255 255 255 / 0%);
  max-width: 100px;
  min-width: 100px;
  margin-left: 4px;
  letter-spacing: 0.8px;
  cursor: pointer;
}

textarea[type="text"],
select {
  width: 100%;
  min-height: 80px;
  padding: 8px 12px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  border: 1px dotted rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 1);
  margin-top: -16px;
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
}

input[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(0 67 112) inset !important;
  -webkit-text-fill-color: rgb(255 255 255) !important;
  border: 1px dotted rgb(0 67 112);
  border-radius: 3px;
}

input[type="checkbox"] {
  min-width: 16px;
  min-height: 16px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

::placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.custom-select {
  position: relative;
  display: flex;
  flex-direction: column;
  color:#000000;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  color: #222222;
  padding: 12px 12px;
  border: 0px solid transparent;
  cursor: pointer;
  user-select: none;
  border-radius: 0px;
  font-size: 14px;
  line-height: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  margin-top: -22px;
  border-radius: 4px;
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
}

/*style the arrow inside the select element:*/

.select-selected:after {
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: rgb(0, 0, 0) transparent transparent transparent;
  float: right;
  margin-top: 7px;
}

.hide_arrow {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 6px;
  bottom: 8px;
  background: rgb(0 67 112);
  z-index: 5;
}

.disa_click {
  pointer-events: none;
  cursor: move;
}

/*point the arrow upwards when the select box is open (active):*/

.select-selected.select-arrow-active:after {
  border-color: transparent transparent rgb(0, 0, 0) transparent;
  margin-top: 0px;
}

/*style the items (options), including the selected item:*/

.select-selected {
  color: #222222;
  padding: 12px 12px;
  border: 0px solid transparent;
  cursor: pointer;
  user-select: none;
  border-radius: 0px;
  font-size: 14px;
  line-height: 16px;
  border: none;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  margin-top: -22px;
  border-radius: 4px;
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
}

.select-items div {
  padding: 12px 12px;
  border: 0px solid transparent;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 16px;
  color: #333338;
  border: none;
  border-bottom: 1px dashed rgb(228 228 228);
  background-color: rgba(255, 255, 255, 0);
}

/*style items (options):*/

.select-items {
  position: absolute;
  padding: 0px;
  background-color: rgba(255, 255, 255, 1);
  top: 55px;
  left: 13px;
  right: 13px;
  z-index: 99;
  border-radius: 4px;
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0px 15px 14px 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 15px 14px 0px;
  transition: 0.3s all;
}

/*hide the items when the select box is closed:*/

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(39, 168, 255, 1);
  color: rgb(255, 255, 255);
  transition: 0.3s all;
  border-radius: 4px;
}

.submit_btn {
  width: 50%;
  height: auto;
  float: right;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  max-width: 120px;
  border-radius: 2px;
  border: none;
  -webkit-appearance: none;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(106, 185, 111, 1);
  background: linear-gradient(
    140deg,
    rgba(106, 185, 111, 1) 0%,
    rgba(106, 185, 111, 0.8) 73%,
    rgba(106, 185, 111, 1) 100%
  );
  padding: 9px 16px 12px 16px;
  transition: 0.2s all;
  cursor: pointer;
}

.submit_btn:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(106, 185, 111, 1);
  transition: 0.4s all;
}

.submit_btn.checked {
  width: 50%;
  height: auto;
  float: right;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  max-width: 86px;
  border-radius: 4px;
  border: none;
  color: rgba(255, 255, 255, 1) !important;
  -webkit-appearance: none;
  padding: 9px 0px 9px 0px;
  transition: 0.2s all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* T O P */

.solar_container {
  width: 100%;
  height: auto;
  transition: 0.4s all;
  position: relative;
  z-index: 1000;
}

/* T O P */

/* B A N N E R */

.banner_container {
  width: 100%;
  height: auto;
  transition: 0.4s all;
  position: relative;
  z-index: 100;
}

.banner_sect {
  float: none;
  height: auto;
  margin-left: auto;
  margin-top: 0px;
  clear: none;
  width: 100%;
  margin-right: auto;
  position: relative;
}

.banner_main {
  width: 100%;
  max-width: 1200px;
  position: absolute;
  top: 24%;
  left: 50%;
  margin-left: -600px;
  color: #fff;
  text-align: left;
  z-index: 200;
}

.banner_title {
  font-size: 32px;
  line-height: 40px;
  color: rgba(47, 47, 47, 1);
  font-weight: 600;
  letter-spacing: -0.5px;
}

.banner_header {
  font-size: 56px;
  line-height: 65px;
  color: rgba(0, 67, 112, 1);
  font-weight: 600;
  letter-spacing: -0.5px;
}

.banner_sub {
  font-size: 18px;
  line-height: 24px;
  padding-top: 8px;
  color: rgba(36, 36, 36, 1);
}

.banner_subpg_main {
  width: 100%;
  max-width: 1200px;
  position: absolute;
  top: 38%;
  left: 50%;
  margin-left: -600px;
  color: #fff;
  text-align: left;
  z-index: 200;
}

.banner_subpg_header {
  font-size: 48px;
  line-height: 65px;
  color: rgba(0, 67, 112, 1);
  font-weight: 600;
  letter-spacing: -0.5px;
}

.banner_subpg_title {
  font-size: 30px;
  line-height: 40px;
  color: rgba(106, 185, 111, 1);
  font-weight: 500;
  letter-spacing: -0.5px;
}

/* B A N N E R */

/* F O O T E R */

.footer_container {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 120;
}

.footer_sect {
  width: 100%;
  height: auto;
  position: relative;
  padding: 56px 2% 64px 2%;
  background-color: rgba(0, 67, 112, 1);
  z-index: 120;
}

.footer_content {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
}

.footer_l {
  width: 45%;
  height: auto;
  float: left;
  padding: 4px 0% 0% 0%;
}

.footer_r {
  width: 50%;
  height: auto;
  float: right;
  position: relative;
  display: flex;
}

.footer_r_cont {
  width: 100%;
  height: auto;
  text-align: left;
  position: relative;
}

.footer_subhead {
  font-size: 15px;
  line-height: 22px;
  color: rgba(240, 240, 240, 1);
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-top: 16px;
}

.footer_navi {
  float: left;
  clear: none;
  width: 33.9%;
  padding: 0px 0%;
}

.footer_navi_title {
  font-size: 20px;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
}

.footer_social {
  float: left;
  clear: none;
  width: 100%;
  padding: 0px 0%;
  display: flex;
}

.footer_icon {
  width: max-content;
  margin-right: 12px;
}

.footer_bottom {
  float: left;
  clear: none;
  width: 100%;
  padding: 8px 2% 24px 2%;
  color: rgba(211, 211, 211, 1);
  background-color: rgba(211, 211, 211, 0);
}

.footer_copyright {
  clear: none;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  text-align: right;
  font-weight: 300;
  font-size: 13px;
}

.footer_info_space {
  padding-left: 0%;
}

/* F O O T E R */

/* V I D E O B G */

.ov_vidbg {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  opacity: 0.2;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

.qa_vidbg {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 50;
  opacity: 0.3;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* V I D E O B G */

@media (max-width: 1367.98px) {
  input[type="text"],
  select {
    max-width: 110px;
    min-width: 110px;
  }
}
@media only screen and (max-width: 1300px) {
  .txt_equip_acc {
    font-weight: 500;
    font-size: 24px;
  }
}

@media (max-width: 1239.98px) {
  .banner_main {
    position: absolute;
    text-align: left;
    margin-left: 2%;
    top: 20%;
    left: 0%;
  }
  .banner_subpg_main {
    position: absolute;
    text-align: left;
    margin-left: 2%;
    top: 28%;
    left: 0%;
  }
  .txt_plant_name {
    font-size: 16px;
  }
  .txt_equip_plant {
    color: #ffffff;
    font-size: 24px;
  }
}

@media (max-width: 1023.98px) {
  .n_support_bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgb(6 31 49 / 98%);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    z-index: 9990;
    display: block;
  }
  .n_support_txt {
    width: 100%;
    padding: 0px 2%;
    position: absolute;
    text-align: center;
    color: #ababab;
    font-weight: 200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
}

@media (max-width: 991.98px) {
  .banner_main {
    margin-left: unset;
    padding: 0px 2%;
    top: 18%;
  }
  .banner_subpg_main {
    margin-left: unset;
    padding: 0px 2%;
    top: 18%;
  }
  .btn_space {
    margin-top: 24px;
  }
  .btn_space2 {
    margin-top: 8px;
  }
  .footer_l {
    width: 32%;
    float: left;
    padding: 4px 0% 0% 0%;
  }
  .footer_r {
    width: 64%;
    float: right;
    display: flex;
  }
}

@media (max-width: 767.98px) {
  .g_title {
    font-size: 24px;
    line-height: 36px;
  }
  .g_h1title {
    font-size: 32px;
    line-height: 44px;
    font-weight: 500;
    display: block;
  }
  .g_h1mws {
    font-size: 40px;
    line-height: 44px;
    font-weight: 600;
    margin-left: 0px;
  }
  .value3_s {
    width: 32px;
  }
  .top_menu_ofi {
    display: none;
  }
  .top_menu {
    display: none;
  }
  .top_menu_res {
    display: block;
  }
  .top_gmenu_res {
    display: block;
  }
  .banner_title {
    font-size: 26px;
    line-height: 32px;
  }
  .banner_header {
    font-size: 44px;
    line-height: 48px;
  }
  .banner_sub {
    font-size: 15px;
    line-height: 20px;
  }
  .btn_space {
    margin-top: 16px;
  }
  .footer_content {
    padding-left: 1%;
  }
  .footer_l {
    width: 100%;
    padding: 0% 0% 40px 0%;
  }
  .footer_r {
    width: 100%;
    float: left;
    display: flex;
  }
  .footer_sect {
    padding: 64px 2% 40px 2%;
  }
  .footer_r {
    display: block;
  }
  .footer_navi {
    float: left;
    clear: none;
    width: 49%;
    padding: 0px 0% 32px 0%;
  }
  .footer_info_space {
    padding-left: 12%;
  }
  .footer_copyright {
    text-align: center;
  }
  .txt_h1txt2_sign {
    font-size: 28px;
  }
}

@media (max-width: 639.98px) {
  .banner_title {
    line-height: 38px;
    text-align: center;
  }
  .banner_header {
    font-size: 36px;
    line-height: 44px;
    text-align: center;
  }
  .banner_sub {
    text-align: center;
  }
  .banner_subpg_header {
    font-size: 36px;
    line-height: 44px;
    text-align: center;
  }
  .banner_main {
    /*top: 12%;*/
  }
  .banner_subpg_main {
    margin-left: unset;
    padding: 0px 2%;
    top: 28%;
    text-align: center;
  }
  .banner_subpg_title {
    font-size: 32px;
    line-height: 56px;
    text-align: center;
  }
  .ov_vidbg {
    height: 120vh;
  }
  .qa_vidbg {
    object-fit: cover;
    height: 120vh;
  }
}

@media (max-width: 575.98px) {
}

.term-bor-r {
  border-right: 1px solid #d8d8d8;
}

/* Gulf Logo */

@font-face {
  font-family: "gulf_logo";
  src: url("../font/gulf_logo.eot?doqyhg");
  src: url("../font/gulf_logo.eot?doqyhg#iefix") format("embedded-opentype"),
    url("../font/gulf_logo.ttf?doqyhg") format("truetype"),
    url("../font/gulf_logo.woff?doqyhg") format("woff"),
    url("../font/gulf_logo.svg?doqyhg#gulf_logo") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icong-"],
[class*=" icong-"] {
  /* use !important to prevent issues with browser extensions that change ../fonts */
  font-family: "gulf_logo" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icong-gulf_logo_colour .path1:before {
  content: "\e900";
  color: rgb(0, 67, 112);
}

.icong-gulf_logo_colour .path2:before {
  content: "\e901";
  margin-left: -4.64453125em;
  color: rgb(106, 185, 111);
}

.icong-gulf_logo_colour .path3:before {
  content: "\e902";
  margin-left: -4.64453125em;
  color: rgb(0, 67, 112);
}

.icong-gulf_logo_wh:before {
  content: "\e903";
  color: #fff;
}

.icong-gulf1_logo_colour .path1:before {
  content: "\e904";
  color: rgb(0, 67, 112);
}

.icong-gulf1_logo_colour .path2:before {
  content: "\e905";
  margin-left: -5.427734375em;
  color: rgb(106, 185, 111);
}

.icong-gulf1_logo_colour .path3:before {
  content: "\e906";
  margin-left: -5.427734375em;
  color: rgb(0, 67, 112);
}

.icong-gulf1_logo_colour .path4:before {
  content: "\e907";
  margin-left: -5.427734375em;
  color: rgb(0, 67, 112);
}

.icong-gulf1_logo_wh:before {
  content: "\e908";
  color: #fff;
}

/* Icon-Status */

@font-face {
  font-family: "ico_status";
  src: url("../font/ico_status.eot?mvo4j5");
  src: url("../font/ico_status.eot?mvo4j5#iefix") format("embedded-opentype"),
    url("../font/ico_status.ttf?mvo4j5") format("truetype"),
    url("../font/ico_status.woff?mvo4j5") format("woff"),
    url("../font/ico_status.svg?mvo4j5#ico_status") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icons-"],
[class*=" icons-"] {
  /* use !important to prevent issues with browser extensions that change ../fonts */
  font-family: "ico_status" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icons-color-status:before {
  content: "\c0000";
}

/* Icon-Moon */

@font-face {
  font-family: "icomoon";
  src: url("../font/icomoon.eot?1rkl65");
  src: url("../font/icomoon.eot?1rkl65#iefix") format("embedded-opentype"),
    url("../font/icomoon.ttf?1rkl65") format("truetype"),
    url("../font/icomoon.woff?1rkl65") format("woff"),
    url("../font/icomoon.svg?1rkl65#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-outline:before {
  content: "\e901";
}

.icon-add-solid:before {
  content: "\e902";
}

.icon-adjust:before {
  content: "\e903";
}

.icon-airplane1:before {
  content: "\e904";
}

.icon-album:before {
  content: "\e905";
}

.icon-align-center:before {
  content: "\e906";
}

.icon-align-justified:before {
  content: "\e907";
}

.icon-align-left:before {
  content: "\e908";
}

.icon-align-right:before {
  content: "\e909";
}

.icon-anchor:before {
  content: "\e90a";
}

.icon-announcement:before {
  content: "\e90b";
}

.icon-apparel:before {
  content: "\e90c";
}

.icon-arrow-down1:before {
  content: "\e90d";
}

.icon-arrow-left1:before {
  content: "\e90e";
}

.icon-arrow-outline-down:before {
  content: "\e90f";
}

.icon-arrow-outline-left:before {
  content: "\e910";
}

.icon-arrow-outline-right:before {
  content: "\e911";
}

.icon-arrow-outline-up:before {
  content: "\e912";
}

.icon-arrow-right1:before {
  content: "\e913";
}

.icon-arrow-thick-down:before {
  content: "\e914";
}

.icon-arrow-thick-left:before {
  content: "\e915";
}

.icon-arrow-thick-right:before {
  content: "\e916";
}

.icon-arrow-thick-up:before {
  content: "\e917";
}

.icon-arrow-thin-down:before {
  content: "\e918";
}

.icon-arrow-thin-left:before {
  content: "\e919";
}

.icon-arrow-thin-right:before {
  content: "\e91a";
}

.icon-arrow-thin-up:before {
  content: "\e91b";
}

.icon-arrow-up1:before {
  content: "\e91c";
}

.icon-artist:before {
  content: "\e91d";
}

.icon-at-symbol:before {
  content: "\e91e";
}

.icon-attachment1:before {
  content: "\e91f";
}

.icon-backspace:before {
  content: "\e920";
}

.icon-backward1:before {
  content: "\e921";
}

.icon-backward-step:before {
  content: "\e922";
}

.icon-badge:before {
  content: "\e923";
}

.icon-battery-full:before {
  content: "\e924";
}

.icon-battery-half:before {
  content: "\e925";
}

.icon-battery-low:before {
  content: "\e926";
}

.icon-beverage:before {
  content: "\e927";
}

.icon-block:before {
  content: "\e928";
}

.icon-bluetooth:before {
  content: "\e929";
}

.icon-bolt:before {
  content: "\e92a";
}

.icon-book-reference:before {
  content: "\e92b";
}

.icon-bookmark1:before {
  content: "\e92c";
}

.icon-bookmarkcopy2:before {
  content: "\e92d";
}

.icon-bookmarkcopy3:before {
  content: "\e92e";
}

.icon-bookmark-outline:before {
  content: "\e92f";
}

.icon-bookmark-outline-add:before {
  content: "\e930";
}

.icon-border-all:before {
  content: "\e931";
}

.icon-border-bottom:before {
  content: "\e932";
}

.icon-border-horizontal:before {
  content: "\e933";
}

.icon-border-inner:before {
  content: "\e934";
}

.icon-border-left:before {
  content: "\e935";
}

.icon-border-none:before {
  content: "\e936";
}

.icon-border-outer:before {
  content: "\e937";
}

.icon-border-right:before {
  content: "\e938";
}

.icon-border-top:before {
  content: "\e939";
}

.icon-border-vertical:before {
  content: "\e93a";
}

.icon-box:before {
  content: "\e93b";
}

.icon-brightness-down:before {
  content: "\e93c";
}

.icon-brightness-up:before {
  content: "\e93d";
}

.icon-browser-window:before {
  content: "\e93e";
}

.icon-browser-window-new:before {
  content: "\e93f";
}

.icon-browser-window-open:before {
  content: "\e940";
}

.icon-bug1:before {
  content: "\e941";
}

.icon-buoy:before {
  content: "\e942";
}

.icon-calculator1:before {
  content: "\e943";
}

.icon-calendar1:before {
  content: "\e944";
}

.icon-camera1:before {
  content: "\e945";
}

.icon-chart:before {
  content: "\e946";
}

.icon-chart-bar:before {
  content: "\e947";
}

.icon-chart-pie:before {
  content: "\e948";
}

.icon-chat-bubble-dots:before {
  content: "\e949";
}

.icon-checkmark1:before {
  content: "\e94a";
}

.icon-checkmark-outline:before {
  content: "\e94b";
}

.icon-cheveron-down:before {
  content: "\e94c";
}

.icon-cheveron-left:before {
  content: "\e94d";
}

.icon-cheveron-outline-down:before {
  content: "\e94e";
}

.icon-cheveron-outline-left:before {
  content: "\e94f";
}

.icon-cheveron-outline-right:before {
  content: "\e950";
}

.icon-cheveron-outline-up:before {
  content: "\e951";
}

.icon-cheveron-right:before {
  content: "\e952";
}

.icon-cheveron-up:before {
  content: "\e900";
}

.icon-clipboard1:before {
  content: "\e953";
}

.icon-close:before {
  content: "\e954";
}

.icon-close-outline:before {
  content: "\e955";
}

.icon-close-solid:before {
  content: "\e956";
}

.icon-cloud1:before {
  content: "\e957";
}

.icon-cloud-upload1:before {
  content: "\e958";
}

.icon-code:before {
  content: "\e959";
}

.icon-coffee:before {
  content: "\e95a";
}

.icon-cog1:before {
  content: "\e95b";
}

.icon-color-palette:before {
  content: "\e95c";
}

.icon-compose:before {
  content: "\e95d";
}

.icon-computer-desktop:before {
  content: "\e95e";
}

.icon-computer-laptop:before {
  content: "\e95f";
}

.icon-conversation:before {
  content: "\e960";
}

.icon-copy1:before {
  content: "\e961";
}

.icon-credit-card1:before {
  content: "\e962";
}

.icon-currency-dollar:before {
  content: "\e963";
}

.icon-dashboard:before {
  content: "\e964";
}

.icon-date-add:before {
  content: "\e965";
}

.icon-dial-pad:before {
  content: "\e966";
}

.icon-directions:before {
  content: "\e967";
}

.icon-document:before {
  content: "\e968";
}

.icon-document-add:before {
  content: "\e969";
}

.icon-dots-horizontal-double:before {
  content: "\e96a";
}

.icon-dots-horizontal-triple:before {
  content: "\e96b";
}

.icon-download1:before {
  content: "\e96c";
}

.icon-duplicate:before {
  content: "\e96d";
}

.icon-edit-copy:before {
  content: "\e96e";
}

.icon-edit-crop:before {
  content: "\e96f";
}

.icon-edit-cut:before {
  content: "\e970";
}

.icon-edit-pencil:before {
  content: "\e971";
}

.icon-education:before {
  content: "\e972";
}

.icon-envelope:before {
  content: "\e973";
}

.icon-exclamation-outline:before {
  content: "\e974";
}

.icon-exclamation-solid:before {
  content: "\e975";
}

.icon-explore:before {
  content: "\e976";
}

.icon-factory:before {
  content: "\e977";
}

.icon-fast-forward:before {
  content: "\e978";
}

.icon-fast-rewind:before {
  content: "\e979";
}

.icon-film1:before {
  content: "\e97a";
}

.icon-filter1:before {
  content: "\e97b";
}

.icon-flag1:before {
  content: "\e97c";
}

.icon-flashlight:before {
  content: "\e97d";
}

.icon-folder1:before {
  content: "\e97e";
}

.icon-folder-outline:before {
  content: "\e97f";
}

.icon-folder-outline-add:before {
  content: "\e980";
}

.icon-format-bold:before {
  content: "\e981";
}

.icon-format-font-size:before {
  content: "\e982";
}

.icon-format-italic:before {
  content: "\e983";
}

.icon-format-text-size:before {
  content: "\e984";
}

.icon-format-underline:before {
  content: "\e985";
}

.icon-forward1:before {
  content: "\e986";
}

.icon-forward-step:before {
  content: "\e987";
}

.icon-gift1:before {
  content: "\e988";
}

.icon-globe:before {
  content: "\e989";
}

.icon-hand-stop:before {
  content: "\e98a";
}

.icon-hard-drive:before {
  content: "\e98b";
}

.icon-headphones1:before {
  content: "\e98c";
}

.icon-heart1:before {
  content: "\e98d";
}

.icon-home1:before {
  content: "\e98e";
}

.icon-hot:before {
  content: "\e98f";
}

.icon-hour-glass1:before {
  content: "\e990";
}

.icon-inbox:before {
  content: "\e991";
}

.icon-inbox-check:before {
  content: "\e992";
}

.icon-inbox-download:before {
  content: "\e993";
}

.icon-inbox-full:before {
  content: "\e994";
}

.icon-indent-decrease1:before {
  content: "\e995";
}

.icon-indent-increase1:before {
  content: "\e996";
}

.icon-information-outline:before {
  content: "\e997";
}

.icon-information-solid:before {
  content: "\e998";
}

.icon-key1:before {
  content: "\e999";
}

.icon-keyboard1:before {
  content: "\e99a";
}

.icon-layers:before {
  content: "\e99b";
}

.icon-library1:before {
  content: "\e99c";
}

.icon-light-bulb:before {
  content: "\e99d";
}

.icon-link1:before {
  content: "\e99e";
}

.icon-list1:before {
  content: "\e99f";
}

.icon-list-add:before {
  content: "\e9a0";
}

.icon-list-bullet:before {
  content: "\e9a1";
}

.icon-load-balancer:before {
  content: "\e9a2";
}

.icon-location1:before {
  content: "\e9a3";
}

.icon-location-current:before {
  content: "\e9a4";
}

.icon-location-food:before {
  content: "\e9a5";
}

.icon-location-gas-station:before {
  content: "\e9a6";
}

.icon-location-hotel:before {
  content: "\e9a7";
}

.icon-location-marina:before {
  content: "\e9a8";
}

.icon-location-park:before {
  content: "\e9a9";
}

.icon-location-restroom:before {
  content: "\e9aa";
}

.icon-location-shopping:before {
  content: "\e9ab";
}

.icon-lock-closed:before {
  content: "\e9ac";
}

.icon-lock-open:before {
  content: "\e9ad";
}

.icon-map1:before {
  content: "\e9ae";
}

.icon-menu1:before {
  content: "\e9af";
}

.icon-mic1:before {
  content: "\e9b0";
}

.icon-minus-outline:before {
  content: "\e9b1";
}

.icon-minus-solid:before {
  content: "\e9b2";
}

.icon-mobile-devices:before {
  content: "\e9b3";
}

.icon-mood-happy-outline:before {
  content: "\e9b4";
}

.icon-mood-happy-solid:before {
  content: "\e9b5";
}

.icon-mood-neutral-outline:before {
  content: "\e9b6";
}

.icon-mood-neutral-solid:before {
  content: "\e9b7";
}

.icon-mood-sad-outline:before {
  content: "\e9b8";
}

.icon-mood-sad-solid:before {
  content: "\e9b9";
}

.icon-mouse:before {
  content: "\e9ba";
}

.icon-music-album:before {
  content: "\e9bb";
}

.icon-music-artist:before {
  content: "\e9bc";
}

.icon-music-notes:before {
  content: "\e9bd";
}

.icon-music-playlist:before {
  content: "\e9be";
}

.icon-navigation-more:before {
  content: "\e9bf";
}

.icon-network:before {
  content: "\e9c0";
}

.icon-news-paper:before {
  content: "\e9c1";
}

.icon-notification1:before {
  content: "\e9c2";
}

.icon-notifications:before {
  content: "\e9c3";
}

.icon-notifications-outline:before {
  content: "\e9c4";
}

.icon-paste1:before {
  content: "\e9c5";
}

.icon-pause1:before {
  content: "\e9c6";
}

.icon-pause-outline:before {
  content: "\e9c7";
}

.icon-pause-solid:before {
  content: "\e9c8";
}

.icon-pen-tool:before {
  content: "\e9c9";
}

.icon-phone1:before {
  content: "\e9ca";
}

.icon-photo:before {
  content: "\e9cb";
}

.icon-php-elephant:before {
  content: "\e9cc";
}

.icon-pin:before {
  content: "\e9cd";
}

.icon-play1:before {
  content: "\e9ce";
}

.icon-play-outline:before {
  content: "\e9cf";
}

.icon-playlist:before {
  content: "\e9d0";
}

.icon-plugin:before {
  content: "\e9d1";
}

.icon-portfolio:before {
  content: "\e9d2";
}

.icon-printer1:before {
  content: "\e9d3";
}

.icon-pylon:before {
  content: "\e9d4";
}

.icon-question1:before {
  content: "\e9d5";
}

.icon-queue:before {
  content: "\e9d6";
}

.icon-radar:before {
  content: "\e9d7";
}

.icon-radarcopy2:before {
  content: "\e9d8";
}

.icon-radio:before {
  content: "\e9d9";
}

.icon-refresh:before {
  content: "\e9da";
}

.icon-reload:before {
  content: "\e9db";
}

.icon-reply1:before {
  content: "\e9dc";
}

.icon-reply-all:before {
  content: "\e9dd";
}

.icon-repost:before {
  content: "\e9de";
}

.icon-save-disk:before {
  content: "\e9df";
}

.icon-screen-full:before {
  content: "\e9e0";
}

.icon-search1:before {
  content: "\e9e1";
}

.icon-send:before {
  content: "\e9e2";
}

.icon-servers:before {
  content: "\e9e3";
}

.icon-share1:before {
  content: "\e9e4";
}

.icon-share-01:before {
  content: "\e9e5";
}

.icon-share-alt:before {
  content: "\e9e6";
}

.icon-shield1:before {
  content: "\e9e7";
}

.icon-shopping-cart:before {
  content: "\e9e8";
}

.icon-show-sidebar:before {
  content: "\e9e9";
}

.icon-shuffle1:before {
  content: "\e9ea";
}

.icon-stand-by:before {
  content: "\e9eb";
}

.icon-star-full1:before {
  content: "\e9ec";
}

.icon-station:before {
  content: "\e9ed";
}

.icon-step-backward:before {
  content: "\e9ee";
}

.icon-step-forward:before {
  content: "\e9ef";
}

.icon-stethoscope:before {
  content: "\e9f0";
}

.icon-store-front:before {
  content: "\e9f1";
}

.icon-stroke-width:before {
  content: "\e9f2";
}

.icon-subdirectory-left:before {
  content: "\e9f3";
}

.icon-subdirectory-right:before {
  content: "\e9f4";
}

.icon-swap:before {
  content: "\e9f5";
}

.icon-tablet1:before {
  content: "\e9f6";
}

.icon-tag:before {
  content: "\e9f7";
}

.icon-target1:before {
  content: "\e9f8";
}

.icon-text-box:before {
  content: "\e9f9";
}

.icon-text-decoration:before {
  content: "\e9fa";
}

.icon-thermometer:before {
  content: "\e9fb";
}

.icon-thumbs-down:before {
  content: "\e9fc";
}

.icon-thumbs-up:before {
  content: "\e9fd";
}

.icon-ticket1:before {
  content: "\e9fe";
}

.icon-time:before {
  content: "\e9ff";
}

.icon-timer:before {
  content: "\ea00";
}

.icon-toolscopy:before {
  content: "\ea01";
}

.icon-translate:before {
  content: "\ea02";
}

.icon-trash:before {
  content: "\ea03";
}

.icon-travel:before {
  content: "\ea04";
}

.icon-travel-bus:before {
  content: "\ea05";
}

.icon-travel-car:before {
  content: "\ea06";
}

.icon-travel-case:before {
  content: "\ea07";
}

.icon-travel-taxi-cab:before {
  content: "\ea08";
}

.icon-travel-train:before {
  content: "\ea09";
}

.icon-travel-walk:before {
  content: "\ea0a";
}

.icon-trophy1:before {
  content: "\ea0b";
}

.icon-tuning:before {
  content: "\ea0c";
}

.icon-upload1:before {
  content: "\ea0d";
}

.icon-usb:before {
  content: "\ea0e";
}

.icon-user1:before {
  content: "\ea0f";
}

.icon-user-add:before {
  content: "\ea10";
}

.icon-user-group:before {
  content: "\ea11";
}

.icon-user-solid-circle:before {
  content: "\ea12";
}

.icon-user-solid-square:before {
  content: "\ea13";
}

.icon-vector:before {
  content: "\ea14";
}

.icon-video-camera1:before {
  content: "\ea15";
}

.icon-view-carousel:before {
  content: "\ea16";
}

.icon-view-column:before {
  content: "\ea17";
}

.icon-view-hide:before {
  content: "\ea18";
}

.icon-view-list:before {
  content: "\ea19";
}

.icon-view-show:before {
  content: "\ea1a";
}

.icon-view-tile:before {
  content: "\ea1b";
}

.icon-volume-down:before {
  content: "\ea1c";
}

.icon-volume-mute1:before {
  content: "\ea1d";
}

.icon-volume-off:before {
  content: "\ea1e";
}

.icon-volume-up:before {
  content: "\ea1f";
}

.icon-wallet:before {
  content: "\ea20";
}

.icon-watch:before {
  content: "\ea21";
}

.icon-window:before {
  content: "\ea22";
}

.icon-window-new:before {
  content: "\ea23";
}

.icon-window-open:before {
  content: "\ea24";
}

.icon-wrench1:before {
  content: "\ea25";
}

.icon-yin-yang:before {
  content: "\ea26";
}

.icon-zoom-in1:before {
  content: "\ea27";
}

.icon-zoom-out1:before {
  content: "\ea28";
}

.icon-home:before {
  content: "\ea29";
}

.icon-home2:before {
  content: "\ea2a";
}

.icon-home3:before {
  content: "\ea2b";
}

.icon-office:before {
  content: "\ea2c";
}

.icon-newspaper:before {
  content: "\ea2d";
}

.icon-pencil:before {
  content: "\ea2e";
}

.icon-pencil2:before {
  content: "\ea2f";
}

.icon-quill:before {
  content: "\ea30";
}

.icon-pen:before {
  content: "\ea31";
}

.icon-blog:before {
  content: "\ea32";
}

.icon-eyedropper:before {
  content: "\ea33";
}

.icon-droplet:before {
  content: "\ea34";
}

.icon-paint-format:before {
  content: "\ea35";
}

.icon-image:before {
  content: "\ea36";
}

.icon-images:before {
  content: "\ea37";
}

.icon-camera:before {
  content: "\ea38";
}

.icon-headphones:before {
  content: "\ea39";
}

.icon-music:before {
  content: "\ea3a";
}

.icon-play:before {
  content: "\ea3b";
}

.icon-film:before {
  content: "\ea3c";
}

.icon-video-camera:before {
  content: "\ea3d";
}

.icon-dice:before {
  content: "\ea3e";
}

.icon-pacman:before {
  content: "\ea3f";
}

.icon-spades:before {
  content: "\ea40";
}

.icon-clubs:before {
  content: "\ea41";
}

.icon-diamonds:before {
  content: "\ea42";
}

.icon-bullhorn:before {
  content: "\ea43";
}

.icon-connection:before {
  content: "\ea44";
}

.icon-podcast:before {
  content: "\ea45";
}

.icon-feed:before {
  content: "\ea46";
}

.icon-mic:before {
  content: "\ea47";
}

.icon-book:before {
  content: "\ea48";
}

.icon-books:before {
  content: "\ea49";
}

.icon-library:before {
  content: "\ea4a";
}

.icon-file-text:before {
  content: "\ea4b";
}

.icon-profile:before {
  content: "\ea4c";
}

.icon-file-empty:before {
  content: "\ea4d";
}

.icon-files-empty:before {
  content: "\ea4e";
}

.icon-file-text2:before {
  content: "\ea4f";
}

.icon-file-picture:before {
  content: "\ea50";
}

.icon-file-music:before {
  content: "\ea51";
}

.icon-file-play:before {
  content: "\ea52";
}

.icon-file-video:before {
  content: "\ea53";
}

.icon-file-zip:before {
  content: "\ea54";
}

.icon-copy:before {
  content: "\ea55";
}

.icon-paste:before {
  content: "\ea56";
}

.icon-stack:before {
  content: "\ea57";
}

.icon-folder:before {
  content: "\ea58";
}

.icon-folder-open:before {
  content: "\ea59";
}

.icon-folder-plus:before {
  content: "\ea5a";
}

.icon-folder-minus:before {
  content: "\ea5b";
}

.icon-folder-download:before {
  content: "\ea5c";
}

.icon-folder-upload:before {
  content: "\ea5d";
}

.icon-price-tag:before {
  content: "\ea5e";
}

.icon-price-tags:before {
  content: "\ea5f";
}

.icon-barcode:before {
  content: "\ea60";
}

.icon-qrcode:before {
  content: "\ea61";
}

.icon-ticket:before {
  content: "\ea62";
}

.icon-cart:before {
  content: "\ea63";
}

.icon-coin-dollar:before {
  content: "\ea64";
}

.icon-coin-euro:before {
  content: "\ea65";
}

.icon-coin-pound:before {
  content: "\ea66";
}

.icon-coin-yen:before {
  content: "\ea67";
}

.icon-credit-card:before {
  content: "\ea68";
}

.icon-calculator:before {
  content: "\ea69";
}

.icon-lifebuoy:before {
  content: "\ea6a";
}

.icon-phone:before {
  content: "\ea6b";
}

.icon-phone-hang-up:before {
  content: "\ea6c";
}

.icon-address-book:before {
  content: "\ea6d";
}

.icon-envelop:before {
  content: "\ea6e";
}

.icon-pushpin:before {
  content: "\ea6f";
}

.icon-location:before {
  content: "\ea70";
}

.icon-location2:before {
  content: "\ea71";
}

.icon-compass:before {
  content: "\ea72";
}

.icon-compass2:before {
  content: "\ea73";
}

.icon-map:before {
  content: "\ea74";
}

.icon-map2:before {
  content: "\ea75";
}

.icon-history:before {
  content: "\ea76";
}

.icon-clock:before {
  content: "\ea77";
}

.icon-clock2:before {
  content: "\ea78";
}

.icon-alarm:before {
  content: "\ea79";
}

.icon-bell:before {
  content: "\ea7a";
}

.icon-stopwatch:before {
  content: "\ea7b";
}

.icon-calendar:before {
  content: "\ea7c";
}

.icon-printer:before {
  content: "\ea7d";
}

.icon-keyboard:before {
  content: "\ea7e";
}

.icon-display:before {
  content: "\ea7f";
}

.icon-laptop:before {
  content: "\ea80";
}

.icon-mobile:before {
  content: "\ea81";
}

.icon-mobile2:before {
  content: "\ea82";
}

.icon-tablet:before {
  content: "\ea83";
}

.icon-tv:before {
  content: "\ea84";
}

.icon-drawer:before {
  content: "\ea85";
}

.icon-drawer2:before {
  content: "\ea86";
}

.icon-box-add:before {
  content: "\ea87";
}

.icon-box-remove:before {
  content: "\ea88";
}

.icon-download:before {
  content: "\ea89";
}

.icon-upload:before {
  content: "\ea8a";
}

.icon-floppy-disk:before {
  content: "\ea8b";
}

.icon-drive:before {
  content: "\ea8c";
}

.icon-database:before {
  content: "\ea8d";
}

.icon-undo:before {
  content: "\ea8e";
}

.icon-redo:before {
  content: "\ea8f";
}

.icon-undo2:before {
  content: "\ea90";
}

.icon-redo2:before {
  content: "\ea91";
}

.icon-forward:before {
  content: "\ea92";
}

.icon-reply:before {
  content: "\ea93";
}

.icon-bubble:before {
  content: "\ea94";
}

.icon-bubbles:before {
  content: "\ea95";
}

.icon-bubbles2:before {
  content: "\ea96";
}

.icon-bubble2:before {
  content: "\ea97";
}

.icon-bubbles3:before {
  content: "\ea98";
}

.icon-bubbles4:before {
  content: "\ea99";
}

.icon-user:before {
  content: "\ea9a";
}

.icon-users:before {
  content: "\ea9b";
}

.icon-user-plus:before {
  content: "\ea9c";
}

.icon-user-minus:before {
  content: "\ea9d";
}

.icon-user-check:before {
  content: "\ea9e";
}

.icon-user-tie:before {
  content: "\ea9f";
}

.icon-quotes-left:before {
  content: "\eaa0";
}

.icon-quotes-right:before {
  content: "\eaa1";
}

.icon-hour-glass:before {
  content: "\eaa2";
}

.icon-spinner:before {
  content: "\eaa3";
}

.icon-spinner2:before {
  content: "\eaa4";
}

.icon-spinner3:before {
  content: "\eaa5";
}

.icon-spinner4:before {
  content: "\eaa6";
}

.icon-spinner5:before {
  content: "\eaa7";
}

.icon-spinner6:before {
  content: "\eaa8";
}

.icon-spinner7:before {
  content: "\eaa9";
}

.icon-spinner8:before {
  content: "\eaaa";
}

.icon-spinner9:before {
  content: "\eaab";
}

.icon-spinner10:before {
  content: "\eaac";
}

.icon-spinner11:before {
  content: "\eaad";
}

.icon-binoculars:before {
  content: "\eaae";
}

.icon-search:before {
  content: "\eaaf";
}

.icon-zoom-in:before {
  content: "\eab0";
}

.icon-zoom-out:before {
  content: "\eab1";
}

.icon-enlarge:before {
  content: "\eab2";
}

.icon-shrink:before {
  content: "\eab3";
}

.icon-enlarge2:before {
  content: "\eab4";
}

.icon-shrink2:before {
  content: "\eab5";
}

.icon-key:before {
  content: "\eab6";
}

.icon-key2:before {
  content: "\eab7";
}

.icon-lock:before {
  content: "\eab8";
}

.icon-unlocked:before {
  content: "\eab9";
}

.icon-wrench:before {
  content: "\eaba";
}

.icon-equalizer:before {
  content: "\eabb";
}

.icon-equalizer2:before {
  content: "\eabc";
}

.icon-cog:before {
  content: "\eabd";
}

.icon-cogs:before {
  content: "\eabe";
}

.icon-hammer:before {
  content: "\eabf";
}

.icon-magic-wand:before {
  content: "\eac0";
}

.icon-aid-kit:before {
  content: "\eac1";
}

.icon-bug:before {
  content: "\eac2";
}

.icon-pie-chart:before {
  content: "\eac3";
}

.icon-stats-dots:before {
  content: "\eac4";
}

.icon-stats-bars:before {
  content: "\eac5";
}

.icon-stats-bars2:before {
  content: "\eac6";
}

.icon-trophy:before {
  content: "\eac7";
}

.icon-gift:before {
  content: "\eac8";
}

.icon-glass:before {
  content: "\eac9";
}

.icon-glass2:before {
  content: "\eaca";
}

.icon-mug:before {
  content: "\eacb";
}

.icon-spoon-knife:before {
  content: "\eacc";
}

.icon-leaf:before {
  content: "\eacd";
}

.icon-rocket:before {
  content: "\eace";
}

.icon-meter:before {
  content: "\eacf";
}

.icon-meter2:before {
  content: "\ead0";
}

.icon-hammer2:before {
  content: "\ead1";
}

.icon-fire:before {
  content: "\ead2";
}

.icon-lab:before {
  content: "\ead3";
}

.icon-magnet:before {
  content: "\ead4";
}

.icon-bin:before {
  content: "\ead5";
}

.icon-bin2:before {
  content: "\ead6";
}

.icon-briefcase:before {
  content: "\ead7";
}

.icon-airplane:before {
  content: "\ead8";
}

.icon-truck:before {
  content: "\ead9";
}

.icon-road:before {
  content: "\eada";
}

.icon-accessibility:before {
  content: "\eadb";
}

.icon-target:before {
  content: "\eadc";
}

.icon-shield:before {
  content: "\eadd";
}

.icon-power:before {
  content: "\eade";
}

.icon-switch:before {
  content: "\eadf";
}

.icon-power-cord:before {
  content: "\eae0";
}

.icon-clipboard:before {
  content: "\eae1";
}

.icon-list-numbered:before {
  content: "\eae2";
}

.icon-list:before {
  content: "\eae3";
}

.icon-list2:before {
  content: "\eae4";
}

.icon-tree:before {
  content: "\eae5";
}

.icon-menu:before {
  content: "\eae6";
}

.icon-menu2:before {
  content: "\eae7";
}

.icon-menu3:before {
  content: "\eae8";
}

.icon-menu4:before {
  content: "\eae9";
}

.icon-cloud:before {
  content: "\eaea";
}

.icon-cloud-download:before {
  content: "\eaeb";
}

.icon-cloud-upload:before {
  content: "\eaec";
}

.icon-cloud-check:before {
  content: "\eaed";
}

.icon-download2:before {
  content: "\eaee";
}

.icon-upload2:before {
  content: "\eaef";
}

.icon-download3:before {
  content: "\eaf0";
}

.icon-upload3:before {
  content: "\eaf1";
}

.icon-sphere:before {
  content: "\eaf2";
}

.icon-earth:before {
  content: "\eaf3";
}

.icon-link:before {
  content: "\eaf4";
}

.icon-flag:before {
  content: "\eaf5";
}

.icon-attachment:before {
  content: "\eaf6";
}

.icon-eye:before {
  content: "\eaf7";
}

.icon-eye-plus:before {
  content: "\eaf8";
}

.icon-eye-minus:before {
  content: "\eaf9";
}

.icon-eye-blocked:before {
  content: "\eafa";
}

.icon-bookmark:before {
  content: "\eafb";
}

.icon-bookmarks:before {
  content: "\eafc";
}

.icon-sun:before {
  content: "\eafd";
}

.icon-contrast:before {
  content: "\eafe";
}

.icon-brightness-contrast:before {
  content: "\eaff";
}

.icon-star-empty:before {
  content: "\eb00";
}

.icon-star-half:before {
  content: "\eb01";
}

.icon-star-full:before {
  content: "\eb02";
}

.icon-heart:before {
  content: "\eb03";
}

.icon-heart-broken:before {
  content: "\eb04";
}

.icon-man:before {
  content: "\eb05";
}

.icon-woman:before {
  content: "\eb06";
}

.icon-man-woman:before {
  content: "\eb07";
}

.icon-happy:before {
  content: "\eb08";
}

.icon-happy2:before {
  content: "\eb09";
}

.icon-smile:before {
  content: "\eb0a";
}

.icon-smile2:before {
  content: "\eb0b";
}

.icon-tongue:before {
  content: "\eb0c";
}

.icon-tongue2:before {
  content: "\eb0d";
}

.icon-sad:before {
  content: "\eb0e";
}

.icon-sad2:before {
  content: "\eb0f";
}

.icon-wink:before {
  content: "\eb10";
}

.icon-wink2:before {
  content: "\eb11";
}

.icon-grin:before {
  content: "\eb12";
}

.icon-grin2:before {
  content: "\eb13";
}

.icon-cool:before {
  content: "\eb14";
}

.icon-cool2:before {
  content: "\eb15";
}

.icon-angry:before {
  content: "\eb16";
}

.icon-angry2:before {
  content: "\eb17";
}

.icon-evil:before {
  content: "\eb18";
}

.icon-evil2:before {
  content: "\eb19";
}

.icon-shocked:before {
  content: "\eb1a";
}

.icon-shocked2:before {
  content: "\eb1b";
}

.icon-baffled:before {
  content: "\eb1c";
}

.icon-baffled2:before {
  content: "\eb1d";
}

.icon-confused:before {
  content: "\eb1e";
}

.icon-confused2:before {
  content: "\eb1f";
}

.icon-neutral:before {
  content: "\eb20";
}

.icon-neutral2:before {
  content: "\eb21";
}

.icon-hipster:before {
  content: "\eb22";
}

.icon-hipster2:before {
  content: "\eb23";
}

.icon-wondering:before {
  content: "\eb24";
}

.icon-wondering2:before {
  content: "\eb25";
}

.icon-sleepy:before {
  content: "\eb26";
}

.icon-sleepy2:before {
  content: "\eb27";
}

.icon-frustrated:before {
  content: "\eb28";
}

.icon-frustrated2:before {
  content: "\eb29";
}

.icon-crying:before {
  content: "\eb2a";
}

.icon-crying2:before {
  content: "\eb2b";
}

.icon-point-up:before {
  content: "\eb2c";
}

.icon-point-right:before {
  content: "\eb2d";
}

.icon-point-down:before {
  content: "\eb2e";
}

.icon-point-left:before {
  content: "\eb2f";
}

.icon-warning:before {
  content: "\eb30";
}

.icon-notification:before {
  content: "\eb31";
}

.icon-question:before {
  content: "\eb32";
}

.icon-plus:before {
  content: "\eb33";
}

.icon-minus:before {
  content: "\eb34";
}

.icon-info:before {
  content: "\eb35";
}

.icon-cancel-circle:before {
  content: "\eb36";
}

.icon-blocked:before {
  content: "\eb37";
}

.icon-cross:before {
  content: "\eb38";
}

.icon-checkmark:before {
  content: "\eb39";
}

.icon-checkmark2:before {
  content: "\eb3a";
}

.icon-spell-check:before {
  content: "\eb3b";
}

.icon-enter:before {
  content: "\eb3c";
}

.icon-exit:before {
  content: "\eb3d";
}

.icon-play2:before {
  content: "\eb3e";
}

.icon-pause:before {
  content: "\eb3f";
}

.icon-stop:before {
  content: "\eb40";
}

.icon-previous:before {
  content: "\eb41";
}

.icon-next:before {
  content: "\eb42";
}

.icon-backward:before {
  content: "\eb43";
}

.icon-forward2:before {
  content: "\eb44";
}

.icon-play3:before {
  content: "\eb45";
}

.icon-pause2:before {
  content: "\eb46";
}

.icon-stop2:before {
  content: "\eb47";
}

.icon-backward2:before {
  content: "\eb48";
}

.icon-forward3:before {
  content: "\eb49";
}

.icon-first:before {
  content: "\eb4a";
}

.icon-last:before {
  content: "\eb4b";
}

.icon-previous2:before {
  content: "\eb4c";
}

.icon-next2:before {
  content: "\eb4d";
}

.icon-eject:before {
  content: "\eb4e";
}

.icon-volume-high:before {
  content: "\eb4f";
}

.icon-volume-medium:before {
  content: "\eb50";
}

.icon-volume-low:before {
  content: "\eb51";
}

.icon-volume-mute:before {
  content: "\eb52";
}

.icon-volume-mute2:before {
  content: "\eb53";
}

.icon-volume-increase:before {
  content: "\eb54";
}

.icon-volume-decrease:before {
  content: "\eb55";
}

.icon-loop:before {
  content: "\eb56";
}

.icon-loop2:before {
  content: "\eb57";
}

.icon-infinite:before {
  content: "\eb58";
}

.icon-shuffle:before {
  content: "\eb59";
}

.icon-arrow-up-left:before {
  content: "\eb5a";
}

.icon-arrow-up:before {
  content: "\eb5b";
}

.icon-arrow-up-right:before {
  content: "\eb5c";
}

.icon-arrow-right:before {
  content: "\eb5d";
}

.icon-arrow-down-right:before {
  content: "\eb5e";
}

.icon-arrow-down:before {
  content: "\eb5f";
}

.icon-arrow-down-left:before {
  content: "\eb60";
}

.icon-arrow-left:before {
  content: "\eb61";
}

.icon-arrow-up-left2:before {
  content: "\eb62";
}

.icon-arrow-up2:before {
  content: "\eb63";
}

.icon-arrow-up-right2:before {
  content: "\eb64";
}

.icon-arrow-right2:before {
  content: "\eb65";
}

.icon-arrow-down-right2:before {
  content: "\eb66";
}

.icon-arrow-down2:before {
  content: "\eb67";
}

.icon-arrow-down-left2:before {
  content: "\eb68";
}

.icon-arrow-left2:before {
  content: "\eb69";
}

.icon-circle-up:before {
  content: "\eb6a";
}

.icon-circle-right:before {
  content: "\eb6b";
}

.icon-circle-down:before {
  content: "\eb6c";
}

.icon-circle-left:before {
  content: "\eb6d";
}

.icon-tab:before {
  content: "\eb6e";
}

.icon-move-up:before {
  content: "\eb6f";
}

.icon-move-down:before {
  content: "\eb70";
}

.icon-sort-alpha-asc:before {
  content: "\eb71";
}

.icon-sort-alpha-desc:before {
  content: "\eb72";
}

.icon-sort-numeric-asc:before {
  content: "\eb73";
}

.icon-sort-numberic-desc:before {
  content: "\eb74";
}

.icon-sort-amount-asc:before {
  content: "\eb75";
}

.icon-sort-amount-desc:before {
  content: "\eb76";
}

.icon-command:before {
  content: "\eb77";
}

.icon-shift:before {
  content: "\eb78";
}

.icon-ctrl:before {
  content: "\eb79";
}

.icon-opt:before {
  content: "\eb7a";
}

.icon-checkbox-checked:before {
  content: "\eb7b";
}

.icon-checkbox-unchecked:before {
  content: "\eb7c";
}

.icon-radio-checked:before {
  content: "\eb7d";
}

.icon-radio-checked2:before {
  content: "\eb7e";
}

.icon-radio-unchecked:before {
  content: "\eb7f";
}

.icon-crop:before {
  content: "\eb80";
}

.icon-make-group:before {
  content: "\eb81";
}

.icon-ungroup:before {
  content: "\eb82";
}

.icon-scissors:before {
  content: "\eb83";
}

.icon-filter:before {
  content: "\eb84";
}

.icon-font:before {
  content: "\eb85";
}

.icon-ligature:before {
  content: "\eb86";
}

.icon-ligature2:before {
  content: "\eb87";
}

.icon-text-height:before {
  content: "\eb88";
}

.icon-text-width:before {
  content: "\eb89";
}

.icon-font-size:before {
  content: "\eb8a";
}

.icon-bold:before {
  content: "\eb8b";
}

.icon-underline:before {
  content: "\eb8c";
}

.icon-italic:before {
  content: "\eb8d";
}

.icon-strikethrough:before {
  content: "\eb8e";
}

.icon-omega:before {
  content: "\eb8f";
}

.icon-sigma:before {
  content: "\eb90";
}

.icon-page-break:before {
  content: "\eb91";
}

.icon-superscript:before {
  content: "\eb92";
}

.icon-subscript:before {
  content: "\eb93";
}

.icon-superscript2:before {
  content: "\eb94";
}

.icon-subscript2:before {
  content: "\eb95";
}

.icon-text-color:before {
  content: "\eb96";
}

.icon-pagebreak:before {
  content: "\eb97";
}

.icon-clear-formatting:before {
  content: "\eb98";
}

.icon-table:before {
  content: "\eb99";
}

.icon-table2:before {
  content: "\eb9a";
}

.icon-insert-template:before {
  content: "\eb9b";
}

.icon-pilcrow:before {
  content: "\eb9c";
}

.icon-ltr:before {
  content: "\eb9d";
}

.icon-rtl:before {
  content: "\eb9e";
}

.icon-section:before {
  content: "\eb9f";
}

.icon-paragraph-left:before {
  content: "\eba0";
}

.icon-paragraph-center:before {
  content: "\eba1";
}

.icon-paragraph-right:before {
  content: "\eba2";
}

.icon-paragraph-justify:before {
  content: "\eba3";
}

.icon-indent-increase:before {
  content: "\eba4";
}

.icon-indent-decrease:before {
  content: "\eba5";
}

.icon-share:before {
  content: "\eba6";
}

.icon-new-tab:before {
  content: "\eba7";
}

.icon-embed:before {
  content: "\eba8";
}

.icon-embed2:before {
  content: "\eba9";
}

.icon-terminal:before {
  content: "\ebaa";
}

.icon-share2:before {
  content: "\ebab";
}

.icon-mail:before {
  content: "\ebac";
}

.icon-mail2:before {
  content: "\ebad";
}

.icon-mail3:before {
  content: "\ebae";
}

.icon-mail4:before {
  content: "\ebaf";
}

.icon-amazon:before {
  content: "\ebb0";
}

.icon-google:before {
  content: "\ebb1";
}

.icon-google2:before {
  content: "\ebb2";
}

.icon-google3:before {
  content: "\ebb3";
}

.icon-google-plus:before {
  content: "\ebb4";
}

.icon-google-plus2:before {
  content: "\ebb5";
}

.icon-google-plus3:before {
  content: "\ebb6";
}

.icon-hangouts:before {
  content: "\ebb7";
}

.icon-google-drive:before {
  content: "\ebb8";
}

.icon-facebook:before {
  content: "\ebb9";
}

.icon-facebook2:before {
  content: "\ebba";
}

.icon-instagram:before {
  content: "\ebbb";
}

.icon-whatsapp:before {
  content: "\ebbc";
}

.icon-spotify:before {
  content: "\ebbd";
}

.icon-telegram:before {
  content: "\ebbe";
}

.icon-twitter:before {
  content: "\ebbf";
}

.icon-vine:before {
  content: "\ebc0";
}

.icon-vk:before {
  content: "\ebc1";
}

.icon-renren:before {
  content: "\ebc2";
}

.icon-sina-weibo:before {
  content: "\ebc3";
}

.icon-rss:before {
  content: "\ebc4";
}

.icon-rss2:before {
  content: "\ebc5";
}

.icon-youtube:before {
  content: "\ebc6";
}

.icon-youtube2:before {
  content: "\ebc7";
}

.icon-twitch:before {
  content: "\ebc8";
}

.icon-vimeo:before {
  content: "\ebc9";
}

.icon-vimeo2:before {
  content: "\ebca";
}

.icon-lanyrd:before {
  content: "\ebcb";
}

.icon-flickr:before {
  content: "\ebcc";
}

.icon-flickr2:before {
  content: "\ebcd";
}

.icon-flickr3:before {
  content: "\ebce";
}

.icon-flickr4:before {
  content: "\ebcf";
}

.icon-dribbble:before {
  content: "\ebd0";
}

.icon-behance:before {
  content: "\ebd1";
}

.icon-behance2:before {
  content: "\ebd2";
}

.icon-deviantart:before {
  content: "\ebd3";
}

.icon-500px:before {
  content: "\ebd4";
}

.icon-steam:before {
  content: "\ebd5";
}

.icon-steam2:before {
  content: "\ebd6";
}

.icon-dropbox:before {
  content: "\ebd7";
}

.icon-onedrive:before {
  content: "\ebd8";
}

.icon-github:before {
  content: "\ebd9";
}

.icon-npm:before {
  content: "\ebda";
}

.icon-basecamp:before {
  content: "\ebdb";
}

.icon-trello:before {
  content: "\ebdc";
}

.icon-wordpress:before {
  content: "\ebdd";
}

.icon-joomla:before {
  content: "\ebde";
}

.icon-ello:before {
  content: "\ebdf";
}

.icon-blogger:before {
  content: "\ebe0";
}

.icon-blogger2:before {
  content: "\ebe1";
}

.icon-tumblr:before {
  content: "\ebe2";
}

.icon-tumblr2:before {
  content: "\ebe3";
}

.icon-yahoo:before {
  content: "\ebe4";
}

.icon-yahoo2:before {
  content: "\ebe5";
}

.icon-tux:before {
  content: "\ebe6";
}

.icon-appleinc:before {
  content: "\ebe7";
}

.icon-finder:before {
  content: "\ebe8";
}

.icon-android:before {
  content: "\ebe9";
}

.icon-windows:before {
  content: "\ebea";
}

.icon-windows8:before {
  content: "\ebeb";
}

.icon-soundcloud:before {
  content: "\ebec";
}

.icon-soundcloud2:before {
  content: "\ebed";
}

.icon-skype:before {
  content: "\ebee";
}

.icon-reddit:before {
  content: "\ebef";
}

.icon-hackernews:before {
  content: "\ebf0";
}

.icon-wikipedia:before {
  content: "\ebf1";
}

.icon-linkedin:before {
  content: "\ebf2";
}

.icon-linkedin2:before {
  content: "\ebf3";
}

.icon-lastfm:before {
  content: "\ebf4";
}

.icon-lastfm2:before {
  content: "\ebf5";
}

.icon-delicious:before {
  content: "\ebf6";
}

.icon-stumbleupon:before {
  content: "\ebf7";
}

.icon-stumbleupon2:before {
  content: "\ebf8";
}

.icon-stackoverflow:before {
  content: "\ebf9";
}

.icon-pinterest:before {
  content: "\ebfa";
}

.icon-pinterest2:before {
  content: "\ebfb";
}

.icon-xing:before {
  content: "\ebfc";
}

.icon-xing2:before {
  content: "\ebfd";
}

.icon-flattr:before {
  content: "\ebfe";
}

.icon-foursquare:before {
  content: "\ebff";
}

.icon-yelp:before {
  content: "\ec00";
}

.icon-paypal:before {
  content: "\ec01";
}

.icon-chrome:before {
  content: "\ec02";
}

.icon-firefox:before {
  content: "\ec03";
}

.icon-IE:before {
  content: "\ec04";
}

.icon-edge:before {
  content: "\ec05";
}

.icon-safari:before {
  content: "\ec06";
}

.icon-opera:before {
  content: "\ec07";
}

.icon-file-pdf:before {
  content: "\ec08";
}

.icon-file-openoffice:before {
  content: "\ec09";
}

.icon-file-word:before {
  content: "\ec0a";
}

.icon-file-excel:before {
  content: "\ec0b";
}

.icon-libreoffice:before {
  content: "\ec0c";
}

.icon-html-five:before {
  content: "\ec0d";
}

.icon-html-five2:before {
  content: "\ec0e";
}

.icon-css3:before {
  content: "\ec0f";
}

.icon-git:before {
  content: "\ec10";
}

.icon-codepen:before {
  content: "\ec11";
}

.icon-svg:before {
  content: "\ec12";
}

.icon-IcoMoon:before {
  content: "\ec13";
}
.content {
  height: 100vh;
}
.text-content {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 5px 15px;
  transform: translate(-50%, -50%);
  background: grey;
}
.column-border {
  border-right: 1px solid white;
}

/* .ant-table-thead > tr > th {
  border-right: 1px solid #c5c3c395;
} */
/* .ant-table-tbody > tr > td {
  border-right: 1px solid #f0f0f0;
} */
/* .ant-table-cell .ant-table-cell-scrollbar {
  display: none;
  width: 0px;
  border: none;
} */
