body * {
  box-sizing: content-box;
}

/* Title & Main Pic */

.ml_container {
  width: 96%;
  height: auto;
  position: relative;
  padding: 64px 2% 48px 2%;
  z-index: 110;
}

.ml_content {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  position: relative;
}

.ml_sentence_l {
  width: 52%;
  height: auto;
  max-width: 440px;
  float: left;
  padding: 0% 0%;
  color: rgba(255, 255, 255, 1);
  font-weight: 200;
  letter-spacing: 0.2px;
}

.ml_image_r {
  width: 100%;
  height: auto;
  position: absolute;
  right: -50%;
  top: -60%;
  transform: translate(0%, 0%);
}

.ml_img {
  float: left;
  margin: 1%;
  clear: none;
  width: 48%;
  padding: 0.5em;
}

.ml_bg_fixed {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center 0%;
  background-size: cover;
  opacity: 0.5;
}

.ml_sect_bg {
  margin: auto;
  z-index: 1;
  position: absolute;
  top: 0%;
}

/* Title & Main Pic */

/* Plant Zone */

.ml_plant_sect {
  width: 96%;
  height: auto;
  position: relative;
  padding: 0px 24px 24px 24px;
  z-index: 110;
  max-width: 1200px;
  margin: auto;
}

.ml_plant_row {
  float: left;
  position: relative;
  padding: 16px 24px 4px 24px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0.95);
}

.ml_plant_row_nav {
  float: left;
  position: relative;
  padding: 16px 4px 4px 4px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0);
}

.ml_runtime_status {
  float: right;
  position: absolute;
  padding: 4px 8px 8px 8px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0);
  border: 1px dashed rgba(255, 255, 255, 0.5);
  bottom: 24px;
  right: 24px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 200;
  letter-spacing: 0.2px;
  line-height: 30px;
  margin: 5px 0 30px 0;
}

.ml_runtime_status_dasbord {
  float: right;
  position: absolute;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0);
  right: 24px;
  color: #000000;
  font-size: 13px;
  font-weight: 200;
  letter-spacing: 0.2px;
  line-height: 30px;
  margin: 5px 0 30px 0;
}

.ml_runtime_state {
  float: right;
  position: absolute;
  background-color: rgba(245, 251, 255, 0);
  border: 0px dashed rgba(255, 255, 255, 0.5);
  bottom: 1px;
  right: 150px;
  color: #ffffff;
  padding-top: 20px;
}

.box-state {
  letter-spacing: 0.5px;
}

.box-status {
  margin-right: -123px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

.box-select {
  background-color: rgba(245, 251, 255, 0);
  border: 0px dashed rgba(255, 255, 255, 0.5);
  width: 120px;
} 

.box-select_day {
  background-color: rgba(245, 251, 255, 0);
  border: 0px dashed rgba(255, 255, 255, 0.5);
  width: 90px;
} 

.normal_sta {
  font-size: 12px;
  color: #90e095;
  margin-right: -4px;
}

.normal_sta_case {
  font-size: 12px;
  color: #90e095;
  margin-right: -4px;
}

.warning_sta {
  font-size: 12px;
  color: #ffcf3b;
  margin-right: -4px;
}
.alert_sta {
  font-size: 12px;
  color: rgba(255, 83, 83, 1);
  margin-right: -4px;
}

.off_sta {
  font-size: 12px;
  color: #d3d3d3;
  margin-right: -4px;
}

.clo_sta {
  font-size: 12px;
  color: rgba(255, 83, 83, 1);
  margin-right: -4px;
}

.ml_12spps {
  width: 100%;
  overflow-x: scroll;
}

.ml_12spps_nav {
  width: 100%;
}

.ml_ipps {
  width: 100%;
  overflow-x: scroll;
}

.ml_ipps_nav {
  width: 100%;
}

.ml_7spps {
  width: 60%;
  margin-right: 24px;
  margin-bottom: 24px;
  overflow-x: scroll;
}

.ml_7spps_nav {
  width: 100%;
}

.ml_biomass {
  width: 38%;
  overflow-x: scroll;
}

.ml_biomass_nav {
  width: 100%;
  margin-left: 0px;
}

.ml_plant_title {
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 16px;
  padding-left: 8px;
}

.ml_plant_group {
  width: 100%;
  margin: auto;
  padding: 0%;
  display: flex;
  flex-wrap: wrap;
}

.ml_plant_col {
  width: 6%;
  min-width: 80px;
  float: left;
  text-align: center;
  margin: 0px 8px 16px 8px;
}

.ml_plant_col_nav {
  width: 6%;
  min-width: 72px;
  float: left;
  text-align: center;
  margin: 0px 8px 8px 8px;
}

.plant_nor_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(106, 185, 111, 1);
  border-radius: 4px;
}

.ml_plant_normal {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px dashed rgb(124 124 124);
  padding: 0px;
  margin: auto;
  height: auto;
  position: relative;
  transition: 0.3s ease;
  min-height: 128px;
  max-height: 128px;
}

.ml_plant_normal_dashbord {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px dashed rgb(124 124 124);
  padding: 0px;
  margin: 40px 0 0 0 ;
  height: auto;
  position: relative;
  transition: 0.3s ease;
  min-height: 250px;
  max-height: 250px;
  min-width: 100px;
  max-width: 100px;
  float: left;
}

.ml_plant_normal_dashbord_sensor {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px dashed rgb(124 124 124);
  padding: 0px;
  margin: 40px 0 0 0 ;
  height: auto;
  position: relative;
  transition: 0.3s ease;
  min-height: 250px;
  max-height: 250px;
  min-width: 100px;
  max-width: 100px;
  float: left;
}


.ml_plant_normal:hover .plant_nor_overlay {
  opacity: 1;
}

.ml_level_sect {
  width: 100%;
  height: 100%;
  bottom: 0px;
  padding: 2px 3px;
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  color: #000000;
  line-height: 16px;
  text-shadow: 0px 1px 1px rgb(255 255 255);
}

.ml_level_sect_dashbord {
  width: 100%;
  height: 100%;
  bottom: 0px;
  padding: 2px 3px;
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  color: #000000;
  line-height: 16px;
  text-shadow: 0px 1px 1px rgb(255 255 255);
}

.ml_chart_off {
  width: 100%;
  background-color: rgba(211, 211, 211, 1);
}

.ml_chart_warn {
  width: 100%;
  background-color: rgba(255, 207, 59, 1);
}

.ml_chart_nor {
  width: 100%;
  background-color: rgba(144, 224, 149, 1);
}

.ml_chart_nor_case {
  width: 100%;
  background-color: rgba(144, 224, 149, 1);
}
.ml_chart_alert {
  width: 100%;
  background-color: rgba(255, 83, 83, 1);
}

.h0 {
  height: 0%;
  position: relative;
}

.h1 {
  height: 1%;
  position: relative;
}

.h2 {
  height: 2%;
  position: relative;
}
.h3 {
  height: 3%;
  position: relative;
}
.h4 {
  height: 10%;
  position: relative;
}

.h8 {
  height: 8%;
  position: relative;
}

.h10 {
  height: 10%;
  position: relative;
}

.h11 {
  height: 11%;
  position: relative;
}

.h12 {
  height: 12%;
  position: relative;
}

.h13 {
  height: 13%;
  position: relative;
}

.h14 {
  height: 14%;
  position: relative;
}

.h15 {
  height: 15%;
  position: relative;
}

.h16 {
  height: 16%;
  position: relative;
}

.h17 {
  height: 17%;
  position: relative;
}

.h18 {
  height: 18%;
  position: relative;
}

.h19 {
  height: 19%;
  position: relative;
}

.h20 {
  height: 20%;
  position: relative;
}

.h21 {
  height: 21%;
  position: relative;
}

.h22 {
  height: 22%;
  position: relative;
}

.h23 {
  height: 23%;
  position: relative;
}

.h24 {
  height: 24%;
  position: relative;
}

.h25 {
  height: 25%;
  position: relative;
}

.h26 {
  height: 26%;
  position: relative;
}

.h27 {
  height: 27%;
  position: relative;
}

.h28 {
  height: 28%;
  position: relative;
}

.h29 {
  height: 29%;
  position: relative;
}

.h30 {
  height: 30%;
  position: relative;
}

/* ----------------------- */

.h31 {
  height: 31%;
  position: relative;
}

.h32 {
  height: 32%;
  position: relative;
}

.h33 {
  height: 33%;
  position: relative;
}

.h34 {
  height: 34%;
  position: relative;
}

.h35 {
  height: 35%;
  position: relative;
}

.h36 {
  height: 36%;
  position: relative;
}

.h37 {
  height: 37%;
  position: relative;
}

.h38 {
  height: 38%;
  position: relative;
}

.h39 {
  height: 39%;
  position: relative;
}

.h40 {
  height: 40%;
  position: relative;
}

/* ----------------------- */

.h41 {
  height: 41%;
  position: relative;
}

.h42 {
  height: 42%;
  position: relative;
}


.h43 {
  height: 43%;
  position: relative;
}


.h44 {
  height: 44%;
  position: relative;
}


.h45 {
  height: 45%;
  position: relative;
}


.h46 {
  height: 46%;
  position: relative;
}


.h47 {
  height: 47%;
  position: relative;
}


.h48 {
  height: 48%;
  position: relative;
}

.h49 {
  height: 49%;
  position: relative;
}

/* ----------------------- */

.h50 {
  height: 50%;
  position: relative;
}

.h51 {
  height: 51%;
  position: relative;
}

.h52 {
  height: 52%;
  position: relative;
}

.h53 {
  height: 53%;
  position: relative;
}

.h54 {
  height: 54%;
  position: relative;
}

.h55 {
  height: 55%;
  position: relative;
}

.h56 {
  height: 56%;
  position: relative;
}

.h57 {
  height: 57%;
  position: relative;
}

.h58 {
  height: 58%;
  position: relative;
}

.h59 {
  height: 59%;
  position: relative;
}

/* ----------------------- */

.h60 {
  height: 60%;
  position: relative;
}

.h61 {
  height: 61%;
  position: relative;
}

.h62 {
  height: 62%;
  position: relative;
}

.h63 {
  height: 63%;
  position: relative;
}

.h64 {
  height: 64%;
  position: relative;
}

.h65 {
  height: 65%;
  position: relative;
}

.h66 {
  height: 66%;
  position: relative;
}

.h67 {
  height: 67%;
  position: relative;
}

.h68 {
  height: 68%;
  position: relative;
}

.h69 {
  height: 69%;
  position: relative;
}

/* ----------------------- */

.h70 {
  height: 70%;
  position: relative;
}

.h71 {
  height: 71%;
  position: relative;
}

.h72 {
  height: 72%;
  position: relative;
}

.h73 {
  height: 73%;
  position: relative;
}

.h74 {
  height: 74%;
  position: relative;
}

.h75 {
  height: 75%;
  position: relative;
}

.h76 {
  height: 76%;
  position: relative;
}

.h77 {
  height: 77%;
  position: relative;
}

.h78 {
  height: 78%;
  position: relative;
}

.h79 {
  height: 79%;
  position: relative;
}

/* ----------------------- */

.h80 {
  height: 80%;
  position: relative;
}

.h81 {
  height: 81%;
  position: relative;
}

.h82 {
  height: 82%;
  position: relative;
}

.h83 {
  height: 83%;
  position: relative;
}

.h84 {
  height: 84%;
  position: relative;
}

.h85 {
  height: 85%;
  position: relative;
}

.h86 {
  height: 86%;
  position: relative;
}

.h87 {
  height: 87%;
  position: relative;
}

.h88 {
  height: 88%;
  position: relative;
}

.h89 {
  height: 89%;
  position: relative;
}

/* ----------------------- */

.h90 {
  height: 90%;
  position: relative;
}

.h91 {
  height: 91%;
  position: relative;
}

.h92 {
  height: 92%;
  position: relative;
}

.h93 {
  height: 93%;
  position: relative;
}

.h94 {
  height: 94%;
  position: relative;
}

.h95 {
  height: 95%;
  position: relative;
}

.h96 {
  height: 96%;
  position: relative;
}

.h97 {
  height: 97%;
  position: relative;
}

.h98 {
  height: 98%;
  position: relative;
}

.h99 {
  height: 99%;
  position: relative;
}

/* ----------------------- */

.h100 {
  height: 100%;
  position: relative;
}

.h0::before {
  content: "";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n1::before {
  content: "1";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n2::before {
  content: "2";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n3::before {
  content: "3";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n4::before {
  content: "4";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n5::before {
  content: "5";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n6::before {
  content: "6";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n7::before {
  content: "7";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n8::before {
  content: "8";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n9::before {
  content: "9";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n10::before {
  content: "10";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n11::before {
  content: "11";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n12::before {
  content: "12";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n13::before {
  content: "13";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n14::before {
  content: "14";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n15::before {
  content: "15";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n16::before {
  content: "16";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n17::before {
  content: "17";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n18::before {
  content: "18";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n19::before {
  content: "19";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.n20::before {
  content: "20";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.n21::before {
  content: "21";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.n22::before {
  content: "22";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.n23::before {
  content: "23";
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.centered-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ml_plant_abnormal {
  border-radius: 4px;
  background-color: rgba(255, 83, 83, 0.8);
  border: 0px dotted rgba(8, 67, 112, 0);
  padding: 16px 12px;
  margin: auto;
  height: auto;
  position: relative;
  transition: 0.3s ease;
}

.plant_abnor_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(255, 83, 83, 0.95);
  cursor: pointer;
  border-radius: 4px;
}

.ml_plant_abnormal:hover .plant_abnor_overlay {
  opacity: 1;
}

.normal_img {
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  position: absolute;
  top: 56%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.normal_img_nav {
  color: rgba(255, 255, 255, 1);
  font-size: 44px;
  position: absolute;
  top: 51%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.ml_plant_sub {
  margin: 6px auto;
  height: auto;
}

.ml_plant_sub_dashbord {
  margin-left: -90px;
  height: auto;
  float: left;
}


.img_thumbs {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.ml_plant_count {
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: #ffffff;
  z-index: 5;
}

/* Plant Zone */

/* Issue Summary */

.issue_container {
  width: 96%;
  height: auto;
  position: relative;
  padding: 0px 2% 48px 2%;
  z-index: 110;
}

.issue_content {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  position: relative;
  margin-bottom: 16px;
}

.issue_l {
  width: 45%;
  height: auto;
  float: left;
  padding: 0% 0%;
}

.issue_date_r {
  width: 45%;
  height: auto;
  float: right;
  padding: 8px 0px 0px 0px;
  color: rgba(255, 255, 255, 1);
  text-align: right;
  font-size: 14px;
}

.issue_table {
  max-width: 1200px;
  margin: auto;
  border-radius: 6px;
  padding: 24px 24px 48px 24px;
  background-color: rgba(245, 251, 255, 0.95);
}

.issue_th {
  width: 100%;
  margin: auto;
  padding: 4px 8px 4px 12px;
  border-radius: 4px;
  margin-bottom: 1px;
  display: flex;
  justify-content: space-between;
}

.issue_td {
  width: 11.5%;
  float: left;
  text-align: left;
}

.issue_td_opc {
  width: 11.5%;
  float: left;
  text-align: left;
}

.issue_td_pty {
  width: 11.5%;
  float: left;
  text-align: left;
}

.th_brow {
  border: 1px dashed rgb(124 124 124 / 20%);
}

.th_row {
  border: 0px dotted rgba(124, 124, 124, 0);
}

.td_date {
  max-width: 140px;
}

.td_plant {
  max-width: 140px;
  text-transform: uppercase;
}
.td_issueid {
  max-width: 140px;
  text-transform: uppercase;
}

.td_issue {
  max-width: 160px;
}

.txt_abnor {
  color: rgba(255, 83, 83, 1);
  font-weight: 400;
}

.td_eqip {
  min-width: 160px;
}

.sta_issue {
  color: rgba(255, 193, 0, 1);
  font-weight: 400;
}

.sta_inprog {
  color: rgba(39, 168, 255, 1);
  font-weight: 400;
}

.sta_accept {
  color: rgba(106, 185, 111, 1);
  font-weight: 400;
}

.td_remark {
  white-space: nowrap;
  width: 44%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td_btn {
  max-width: 140px;
  text-align: center;
}

.btn_arr {
  padding: 6px;
  background-color: rgb(255 255 255 / 15%);
  border-radius: 2px;
  vertical-align: -1px;
  color: rgba(228, 123, 75, 1);
  font-weight: 600;
  font-size: 12px;
}

.span_waring {
  padding-left: 20px;
}

.span_off {
  padding-left: 37px;
}

.span_off_case {
  padding-left: 20px;
}

.ml_dasdord {
  width: 100%;
  /* overflow-x: scroll; */
  height: 400px;
}

.ml_dasdord_pi {
  width: 100%;
  /* overflow-x: scroll; */
  height: 450px;
}

.disabled {
  pointer-events: none; 
  opacity: 0.5; 
}

.nav_top_container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: flex-start; 
  padding: 20px 5px 10px 35px;
}

.nav_top_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 25px; 
  margin-bottom: 25px;
}

.nav_top_img {
  margin-right: 15px;
}

.img_nav_homenew {
  width: 50px;
  height: 50px; 
  margin-top: -25px;
  margin-left: 5px;
}

.nav_text_homenew {
  margin-top: 20px;
  margin-bottom: -20px;
  font-weight: 500;
}

.nav_text_time {
  margin-top: 30px;
  margin-left: 100px;
}

.ant-select-selection-placeholder {
  color: white !important;
}


/* Issue Summary */

@media only screen and (max-width: 1264px) {
  .ml_ipps {
    width: 95vw;
    overflow-x: scroll;
  }
  .ml_12spps {
    width: 95vw;
    overflow-x: scroll;

    /* min-width: 1122px; */
  }
  .ml_12spps_nav {
    width: 100%;
  }
  .ml_7spps {
    width: 95vw;
    overflow-x: scroll;

    /* min-width: 616px; */
  }
  .ml_biomass {
    width: 95vw;
  }
  .ml_plant_sect {
    /* min-width: 1122px; */
  }
  .ml_plant_col {
    width: 6%;
    min-width: 65px;
    /* min-width: 80px; */
    float: left;
    text-align: center;
  }
}

@media only screen and (max-width: 1240px) {
  .ml_plant_sub {
    margin: 8px auto 6px auto;
  }
  .td_eqip {
    min-width: 181px;
    width: 22%;
  }
  .td_btn {
    max-width: 119px;
  }
}

@media only screen and (max-width: 1214px) {
  .td_eqip {
    min-width: 181px;
    width: 19%;
  }
}

@media only screen and (max-width: 1061px) {
  .td_btn {
    max-width: 105px;
  }
}

@media only screen and (max-width: 1050px) {
  .td_eqip {
    min-width: 170px;
    width: 18%;
  }
}
