html {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    position: relative;
}

.sign_panel {
    width: 100%;
    height: 100vh;
    text-align: left;
    margin: 0px auto 24px auto;
}

.sign_col {
    width: 96%;
    max-width: 980px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
    z-index: 100;
}

.sign_logo {
    width: 40%;
    float: left;
    font-size: 18px;
    line-height: 48px;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

.sign_content {
    width: 56%;
    float: right;
    text-align: left;
    background-color: rgba(245, 251, 255, 0.85);
    padding: 40px 28px 48px 32px;
    border-radius: 6px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(4px);
}

.sign_footer_fixed {
    position: fixed;
    bottom: 0px;
}

.sign_bg_fixed {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center 50%;
    background-size: cover;
}

.sign_sect_bg {
    margin: auto;
    z-index: 3;
    position: fixed;
    top: 0%;
}

.sign_linebg_fixed {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center 0%;
    background-size: auto;
}

.sign_line_bg {
    margin: auto;
    z-index: 4;
    position: fixed;
    top: 0%;
    opacity: 0.9;
}

.sign_btn {
    width: max-content;
    background-color: rgba(0, 67, 112, 1);
    color: rgba(255, 255, 255, 0.8);
    padding: 8px 16px;
    margin: 32px 0px 0px 0px;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s ease;
}

.sign_btn:hover {
    background-color: rgba(106, 185, 111, 1);
    color: rgba(255, 255, 255, 1);
    transition: 0.5s ease;
}

@media only screen and (max-width: 1250px) {
    .sign_col {
        transform: translate(-50%, -65%);
    }
}

@media only screen and (max-width: 767.9px) {
    html {
        overflow-y: unset !important;
    }
    .sign_col {
        transform: translate(-50%, -64%);
    }
    .sign_logo {
        width: 70%;
        float: none;
        position: relative;
        top: unset;
        transform: unset;
        margin: 40px auto 40px auto;
        text-align: center;
    }
    .sign_content {
        width: 56%;
        min-width: 360px;
        float: none;
        text-align: center;
        margin: auto;
    }
    .sign_btn {
        margin: 32px auto 0px auto;
    }
}