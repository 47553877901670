/* Title & Main Pic */

/* .eq_container {
  width: 96%;
  height: auto;
  position: relative;
  padding: 6px 2% 0px 2%;
  z-index: 110;
  max-width: 1200px;
  margin: 40px auto;
  border-radius: 12px;
}

 .eq_top_sect {
  width: 100%;
  float: left;
  position: relative;
  padding: 16px 0px 20px 16px;
  background: rgb(22, 60, 87);
  background: linear-gradient(
    109deg,
    rgb(128 128 128 / 8%) 0%,
    rgb(13 45 67 / 0%) 59%
  );
  border-radius: 12px;
}  */

.eq_top_bg {
  width: 50vw;
  height: 50vh;
  position: absolute;
  border-radius: 12px;
  background-image: url(../img/top_bg.png);
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 49%;
  opacity: 0.2;
  left: 0px;
  top: 0px;
  z-index: 100;
}

.eq_top_img {
  width: 100%;
  height: auto;
  position: absolute;
  right: -66%;
  top: -8%;
  transform: translate(0%, 0%);
  z-index: 300;
}

/* .eq_sentence {
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: auto;
  padding: 0% 0%;
  position: relative;
  color: rgba(255, 255, 255, 1);
  font-weight: 200;
  letter-spacing: 0.2px;
  text-align: center;
  display: inline-flex;
}

.eq_sentence_title {
  width: max-content;
  min-width: 313px;
  height: auto;
  text-align: left;
  padding-right: 48px;
} */

.eq_image_r {
  width: 100%;
  height: auto;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(46%, -14%);
}

/* .eq_sum_issue {
  width: 100%;
  margin: 40px auto 0px auto;
  max-width: 1200px;
  position: relative;
  left: 24px;
  padding: 24px 24px 32px 24px;
  background: linear-gradient(
    109deg,
    rgb(73 104 128 / 24%) 0%,
    rgb(23 56 79 / 0%) 59%
  );
  border-radius: 8px;
  z-index: 150;
  -webkit-box-shadow: rgb(0 0 0 / 8%) 0px 0px 5px 0px;
  box-shadow: rgb(0 0 0 / 2%) -6px -6px 9px 0px;
  z-index: 500;
} */

.eq_label {
  cursor: default;
  top: -40px;
  position: relative;
  width: fit-content;
  padding: 2px 10px;
  background-color: rgb(20 51 77);
  border-radius: 6px;
  margin-left: -4px;
  color: rgba(208, 208, 208, 1);
  font-weight: 400;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.eq_summary_sec {
  width: 100%;
  height: auto;
  float: left;
  margin-top: -16px;
  display: flex;
}

.eq_sum_data {
  width: 86%;
  height: auto;
  float: left;
  position: relative;
  z-index: 400;
  max-width: min-content;
  margin-left: 32px;
}

/* .eq_sum_data_title {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 16px;
} */

.eq_sum_data_cont {
  width: 100%;
  height: auto;
  float: left;
  display: inline-flex;
}

.eq_sum_space2 {
  max-width: 180px;
  min-width: 180px;
}

.eq_case_btn {
  margin-right: 12px;
  float: right;
  -webkit-box-shadow: rgb(0 0 0 / 24%) 2px 4px 24px 0px;
  box-shadow: rgb(0 0 0 / 24%) 2px 4px 24px 0px;
  border: 2px solid rgb(255 255 255 / 28%);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all;
  transform: scale(1);
}

.eq_case_btn:hover {
  border: 2px solid rgb(255 255 255 / 28%);
  filter: brightness(1.4);
  transition: 0.3s all;
  transform: scale(1.1);
}

/* .img_case_btn {
  width: 100%;
  max-width: 36px;
  height: auto;
} */

.img_main {
  width: 100%;
  max-width: 920px;
  height: auto;
}

.eq_img {
  float: left;
  margin: 1%;
  clear: none;
  width: 48%;
  padding: 0.5em;
}

.eq_bg_fixed {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center 0%;
  background-size: cover;
  opacity: 0.5;
}

.eq_sect_bg {
  margin: auto;
  z-index: 1;
  position: absolute;
  top: 0%;
}

/* Title & Main Pic */

/* Plant Zone */

.eq_cate_sect {
  width: 96%;
  height: auto;
  position: relative;
  padding: 0px 2% 24px 2%;
  z-index: 110;
  max-width: 1200px;
  margin: auto;
}

.eq_plant_row {
  float: left;
  position: relative;
  padding: 20px 24px 4px 24px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0);
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.eq_12spps {
  width: 100%;
}

.eq_plant_title {
  width: 100%;
  text-align: center;
  margin: 0px auto;
}

.eq_plant_title_plant {
  width: 40%;
  float: left;
  font-size: 18px;
}

.eq_plant_title_cate {
  width: 40%;
  float: right;
  text-align: right;
}

.eq_plant_group {
  width: 100%;
  margin: auto;
  padding: 0%;
  display: flex;
  flex-wrap: wrap;
}

.eq_plant_col {
  width: 9.25%;
  min-width: 80px;
  float: left;
  text-align: center;
  margin: 0px 4px 8px 4px;
}

.eq_plant_normal {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px dotted rgba(8, 67, 112, 0.1);
  padding: 0px 12px;
  margin: auto;
  height: auto;
  position: relative;
  transition: 0.5s ease;
}

.eq_plant_ico {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 2px;
  cursor: pointer;
}

.eq_plant_normal:hover .eq_plant_ico {
  opacity: 1;
}

.eq_plant_img {
  position: absolute;
  text-align: center;
  padding: 0px 12px;
}

.plant_nor_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(106, 185, 111, 1);
  border-radius: 4px;
}

.eq_plant_normal:hover .plant_nor_overlay {
  opacity: 1;
}

.eq_plant_abnormal {
  border-radius: 4px;
  background-color: rgba(255, 83, 83, 0.8);
  border: 0px dotted rgba(8, 67, 112, 0);
  padding: 16px 12px;
  margin: auto;
  height: auto;
  position: relative;
  transition: 0.5s ease;
}

.plant_abnor_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(255, 83, 83, 0.95);
  cursor: pointer;
  border-radius: 4px;
}

.eq_plant_abnormal:hover .plant_abnor_overlay {
  opacity: 1;
}

.eq_plant_sub {
  margin: 4px auto 0px auto;
  height: auto;
}

.img_thumbs {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.img_equip {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.eq_na {
  filter: opacity(20%);
}

.data_container {
  width: 100%;
  height: auto;
  position: relative;
  padding: 16px 2% 24px 2%;
  z-index: 110;
  overflow-x: scroll;
}

/* .data_content_title {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  position: relative;
  padding-bottom: 32px;
}

.data_content {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  position: relative;
  padding: 40px 24px 40px 24px;
  border-radius: 6px;
  background-color: rgba(245, 251, 255, 0.95);
} */

/* Plant Zone */

@media only screen and (max-width: 1250px) {
  /* .eq_container {
    min-width: 1024px;
  } */
  .eq_12spps {
    min-width: 1122px;
  }
  /* .data_content {
    min-width: 1122px;
  } */
  .eq_cate_sect {
    min-width: 1024px;
  }
  .eq_plant_col {
    float: left;
    text-align: center;
  }
}

.graph_equip_search {
  display: inline;
}

.text-span  {
  color: #ffff;
  font-size: 13px;
  margin: 20px;
  font-family: 'Poppins';
  font-weight: 100;
  line-height: 2.5;
}

.bth-search-graph{
  margin-left: 15px;
  width: 30px;
  height: 30px;
  border-radius: 9px;
}

.scrollgraph{
  overflow: scroll;
  width: 93%;
  height: 100vh;
  float: left;
  margin-left: -200px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 15px; /* Width of the entire scrollbar */
  float: right;
  margin-right: -10px;
  color:  #0EC6E7;
  
}


