/* .ant-table-thead > tr > th {
  background-color: #fbfbfb !important;
}
.ant-modal-body {
  background-color: transparent !important;
  height: auto;
  width: auto;
} */
.ant-modal-content {
  top: 35vh;
  padding: 0;
  background-color: transparent !important;
}
.ant-modal-root {
  overflow-y: scroll;
  display: flex;
}
.ant-modal-mask {
  backdrop-filter: blur(5px);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: white;
  position: relative;
  background-color: rgba(245, 251, 255, 0);
  /* color: #ffffff; */
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
}
.ant-select-arrow {
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: rgb(255, 255, 255, 1) transparent transparent transparent;
  float: right;
  margin-top: -1px;
}
.ant-select-show-arrow ant-show-open {
  transform: rotate(180deg);
}
/* .anticon {
  display: none;
} */

.ant-select-selection-placeholder {
  color: black;
}

.ant-form-item.custom-item {
  margin: -18px 0px 0px 0px;
}
.ant-form-item.custom-item-remark {
  margin: -0px 0px 0px 0px;
}

/* .ant-table-container table > thead > tr:first-child th:last-child {
  display: none;
} */

.cate_date_opt_txt.issue_summary {
  margin-right: -175px;
}
.ant-checkbox-inner{
  position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.ant-modal-body-save {
  /* background-color: #E0F3F7; */
  font-size: 18px;
  font-weight: bold;
  text-shadow: 0 0 0.2em #8F7
}
